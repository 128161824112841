.publish-menu-container {
  display:inline-block;
  width:100%;
}

.publish-menu-triangle-container {
  display:flex;
}

.publish-menu-triangle {
  margin-left:auto;
  margin-right:35px;

}


.publish-menu {
  background-color:rgba(64,64,64,1.0);
  border:1px solid rgba(51,51,51,1.0);
  height:100%;
  width:455px;
  border-radius:8px;
  color:rgba(255,255,255,1);
  margin-top:-9px;
  pointer-events:auto;
}

@media (max-width: 455px) {
  .publish-menu {
    width:100%;

  }

}

.publish-menu-top-title-bar {
  height:31px;
  width:100%;
  font-size: 11px;
  display:flex;
  align-items: center;
  border-bottom: 1px solid rgba(51,51,51,1.0);
}

.publish-menu-top-title-bar-label {
  text-transform: uppercase;
  margin-left:30px;

}


.unpublished-changes-bar {
  height:40px;
  width:100%;
  font-size: 15px;
  display:flex;
  align-items: center;
  color: rgb(232, 218, 94, 0.9);
  border-bottom: 1px solid rgba(51,51,51,1.0);
}

.publish-edits-icon {
  margin-right: 3px;
}

.unpublished-changes-container {
  margin-left: 25px;
  display:flex;
  align-items: center;
  width: 100%;
}

.publish-status-bar {
  width:100%;
  font-size: 15px;
  display:flex;
  align-items: center;
  border-bottom: 1px solid rgba(51,51,51,1.0);
}

.publish-status-bar-container{
  padding-top: 11px;
  padding-bottom: 11px;

}


.publish-status-bar-project-url-container {
  margin-left:30px;
  display:flex;
  align-items: center;
  width:100%
}


.publish-status-bar-project-url {
  font-size:18px;
  color:rgba(246,246,246,1);
  width:185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display:inline-block;
  margin-right:11px;
}

.open-project-arrow-icon-container {
  display:inline-block;
  opacity:0.53;

}

.open-project-arrow-icon-container:hover {
  display:inline-block;
  opacity:1.0;
}

.open-project-arrow-icon {
  display: flex;
  align-items: center;
  height: 100%;

}

.open-project-arrow-icon-svg {
  display:block;
  height:100%;
  margin:auto;

}

.publish-status-bar-secondary-row-container {
  margin-top:4px;
  margin-left:30px;
  padding-bottom:4px;
  display:block;
  width:100%;

}

.publish-status-bar-publish-state {
  display:inline-block;
  color:rgba(52,142,255,1);

}

.publish-status-bar-publish-state-error {
  display:inline-block;
  color:#EB635C;

}

.publish-status-bar-circle-separator {
  display:inline-block;
  margin:auto 12px auto 12px;
}

.publish-status-bar-circle-separator-container {
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;

}

.publish-status-bar-circle-separator-icon {
  display:inline-block;
  align-self: center;

}

.publish-status-bar-unpublish {
  text-decoration: underline;
  display:inline-block;
  opacity:0.53;
}

.publish-status-bar-unpublish:hover {
  opacity:1.0;
}



.quantified-skills-bar {
  padding-top:11px;
  padding-bottom:11px;
  width:100%;
  font-size: 15px;
  display:flex;
  align-items: center;
  border-bottom: 1px solid rgba(51,51,51,1.0);


}

.quantified-skills-bar-label-container {
  margin-left:30px;
  margin-right:30px;

}


.quantified-skills-bar-label {
  font-size:18px;
  color:rgba(246,246,246,1);
  width:246px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantified-skills-secondary-row-container {
  margin-top:4px;
  padding-bottom:4px;

}

.quantified-skills-privacy-state {
  display:inline-block;
  color:rgba(52,142,255,1);

}

.quantified-skills-state-icon-container {
  display:inline-block;
  margin-right:4px;
}

.quantified-skills-state-icon {
  display:flex;
  align-items: center;
}

.quantified-skills-state-icon.svg {
  margin: auto auto auto auto;
  display: block;

}

.quantified-skills-state-label {
  display:inline-block;

}

.publish-status-bar-circle-separator {
  display:inline-block;
  margin:auto 12px auto 12px;
}

.publish-status-bar-circle-separator-container {
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;

}

.publish-status-bar-circle-separator-icon {
  display:inline-block;
  align-self: center;

}

.quantified-skills-toggle-privacy {
  display:inline-block;
  opacity:0.53
}
.quantified-skills-toggle-privacy:hover{
  opacity:1.0
}

.quantified-skills-body-text {
  display:inline-block;
  opacity:0.53
}

.quantified-skills-learn-more {
  display:inline-block;
  text-decoration: underline;
  opacity:0.53
}

.quantified-skills-learn-more:hover {
  opacity:1.0
}

.optInToQuantifyRow {
display:flex;
align-items: center;

}



.optInToQuantifyPubliclyRowContainer {
  display:flex;
  align-items: center;
  margin-top:8px;
  margin-bottom:5px;
}



.checkBox {
  height:15px;
  width:15px;
  background: #363636;
  border: 1px solid #333333;
  border-radius: 2px;
  display:inline-block;

}

.checkBox:hover {
  background: #303030;

}

.checkMark {
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:3px;
  margin-left:2.5px;
}

.checkBoxLabel {
  display:inline-block;
  margin-left:9px;

}




.publish-menu-buttons {
  height:59px;
  display:flex;
  align-content:center;
  font-size: 15px;
}

.publish-menu-buttons-container {
  margin:auto 0px auto 30px;

}

.publish-menu-publish-button {
  display:inline-block;
  height:30px;
  width:126px;
  background-color: rgba(52,142,255,1);
  color:white;
  border-radius: 8px;
  margin-right:8px;

}

.publish-menu-publish-button-saving {
  display:inline-block;
  height:30px;
  width:126px;
  opacity: 0.6;
  background-color: rgba(52,142,255, 1);
  color:white;
  border-radius: 8px;
  margin-right:8px;

}

.publish-menu-publish-button:hover{
  opacity:0.7;

}

.publish-menu-publish-button-disabled {
  display:inline-block;
  height:30px;
  width:126px;
  background-color: #EB635C;
  color:white;
  border-radius: 8px;
  margin-right:8px;
  opacity:0.6;

}

.publish-menu-publish-button-container {
  display:flex;
  height:100%
}

.publish-menu-publish-button-label {
  margin:auto auto auto auto;
  display:inline-block;

}


.publish-menu-close-button {
  display:inline-block;
  height:30px;
  width:126px;
  background-color: none;
  color:#ADADAD;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #868789 inset;

}

.publish-menu-close-button:hover {
  opacity:0.7;

}

.publish-menu-close-button-container {
  display:flex;
  height:100%
}

.publish-menu-close-button-label {
  margin:auto auto auto auto;
  display:inline-block;

}

.publish-menu-close-button {
  display:inline-block;

}
