.project-details{
  height:100%;
  margin-bottom:100px;
  font-size:22px;
  line-height: 1.4;
}

.project-details-container {
  margin-left:25px;
  margin-right:25px;
}

.project-details-section {
  margin-top:84px;
  width:100%;


}

.project-details-section-title-container{
  margin-bottom:25px;
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-section-title {
  font-weight: 500;
  font-size: 35px;
  color: #242424;
  letter-spacing: 0;
  width:700px;

}

.project-details-section-textarea-container{
  margin-bottom:40px;
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-section-textarea {
  font-size: 22px;
  color: #242424;
  font-family: Brandon;
  letter-spacing: 0;
  width:700px;
  min-height:31px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.project-details-section-textarea-for-draft {
  font-size: 22px;
}


.project-details-section-textarea-error {
  font-size: 22px;
  color: #242424;
  font-family: Brandon;
  letter-spacing: 0;
  width:700px;
  min-height:31px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.project-details-section-textarea-error::-webkit-input-placeholder {
  color:#EB635C;
  opacity:0.8;
}

.project-details-section-textarea-error:-moz-placeholder { /* Firefox 18- */
color: #EB635C;
}

.project-details-section-textarea-error::-moz-placeholder {  /* Firefox 19+ */
color: #EB635C;
}

.project-details-section-textarea-error:-ms-input-placeholder {
color: #EB635C;
}



.project-details-section-metadata-row-container{
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-section-metadata-row {
  width:700px;
  border-top: solid 1px #EBEDF2;
  border-bottom: solid 1px #EBEDF2;
  margin-top:-1px;
  font-size:18px;
  padding-top:19px;
  padding-bottom:19px;

}


.project-details-section-metadata-row-flex-container {
  display:flex;
  width:100%;

}

.project-details-section-metadata-row-label{
  width:156px;
  font-weight: 500;

}

.project-details-section-metadata-row-textarea{
  width:100%;
  height:26px;
  font-family: Brandon;
  font-size: 18px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  padding:0 0 0 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.project-details-section-metadata-row-textarea-error{
  width:100%;
  height:26px;
  font-family: Brandon;
  font-size: 18px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  padding:0 0 0 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color:#EB635C;
}



.project-details-section-metadata-row-textarea-error::-webkit-input-placeholder {
  color:#EB635C;
  opacity:0.8;
}

.project-details-section-metadata-row-textarea-error:-moz-placeholder { /* Firefox 18- */
color: #EB635C;
}

.project-details-section-metadata-row-textarea-error::-moz-placeholder {  /* Firefox 19+ */
color: #EB635C;
}

.project-details-section-metadata-row-textarea-error:-ms-input-placeholder {
color: #EB635C;
}












/* Skills area */

.project-details-skill-area-flex-container{
  display:flex;


}

.project-details-skill-area {
  width:900px;
  background: #FFFFFF;
  border: 1px solid #F7F8FA;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
  margin: 50px auto 0 auto;

}

.project-details-skill-area-container {
  margin-left:25px;
  margin-right:25px;


}

.project-details-skill-area-label {
  margin-top: 41px;
  font-weight: 500;
  font-size:35px;
}

.project-details-skill-area-body {
  margin-top:15px;
  font-size:18px;

}

.underline {
  text-decoration:underline;
  opacity:1;

}

.underline:hover {
  opacity:0.6;

}

.skill-area-divider {
  border-bottom: 1px solid #EBEDF2;

}

.add-skill-button {
  color: #348EFF;
  text-align: center;
  opacity:1;
  padding-top:20px;
  padding-bottom:20px;
  font-size:18px;
}

.add-skill-button:hover {
  opacity:0.6;
}

.skills-container {
  display:flex;
  flex-flow: row wrap;
  margin-top:15px;
  margin-bottom:48px;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;

}

.project-details-skill-area-text-container{
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-skill-area-text{
  max-width:700px;
  display:flex;
  flex-basis:700px;
}
