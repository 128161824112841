.side-toolbar {
  position: absolute;
  top: -.1em;
  left: -2.5em;
}

.side-toolbar-toggle {
  border: 1px solid #EBEDF2;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  height:36px;
  width:37px;
  padding-top:2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-toolbar-toggle > * {
  display: inline-block;
  transition: 200ms;
  font-weight: bold;
}

.side-toolbar-toggle.expanded > * {
  transform: rotate(45deg);
}

.side-toolbar-buttons {
  position: absolute;
  top: -.45em;
  left: 2.5em;
  border: 1px solid #EBEDF2;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
}

.side-toolbar__button {
  border: none;
  cursor: pointer;
  transition: 200ms;
  background: #fff;
  line-height: 1em;
  position: relative;
  border-right: 1px solid #EBEDF2;
  align-self: stretch;
  width:62px;
  display: flex;
  justify-content: center;
  align-items: center;
  height:55px;
}



.side-toolbar__button:hover {
  /* background: #FAFBFE; */
}

.side-toolbar__button svg {
  vertical-align: middle;
  transform:scale(0.85);
}

.side-toolbar__button:first-child {
}

.side-toolbar__button:last-child {
  border-right: none;
}

.side-toolbar__button input[type="file"] {
  position: absolute;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
