.project-hero {
  height:405px;
  width:100%;
  overflow:hidden;
  position:relative;
}

.hero-background-image {
  width:100%;
  height:100%;
  position:absolute;
  z-index:-2;
  object-fit:cover;
  margin-top:auto auto auto auto;
}

.hero-temp-background-image {
  width:100%;
  height:100%;
  position:absolute;
  z-index:-1;
  object-fit:cover;
  margin-top:auto auto auto auto;
}

.project-hero-container {
  background-color:rgba(0,0,0,0.73);
  display:flex;
  height:100%;
  margin-top:0;
  z-index:0;
  justify-content: center;

}

.project-hero-group-container {
  margin-left:25px;
  margin-right:25px;
  display:flex;
  width:814px;
  justify-content: center;
  align-items: center;
}

.project-hero-group {
  width:100%;

}


.editable-project-title {
  min-height:81px;
  font-weight:500;
  width:100%;
  font-family: Brandon;
  font-size: 60px;
  border: none;
  color: rgb(255, 255, 255);
  text-align: center;
  overflow: none;
  outline: none;
  background-color: transparent;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}

.editable-project-title-error {
  min-height:81px;
  font-weight:500;
  width:100%;
  font-family: Brandon;
  font-size: 60px;
  border: none;
  color: rgb(255, 255, 255);
  text-align: center;
  overflow: none;
  outline: none;
  background-color: transparent;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}

.editable-project-title-error::-webkit-input-placeholder {
  color:#EB635C;
  opacity:0.8;
}

.editable-project-title-error:-moz-placeholder { /* Firefox 18- */
color: #EB635C;
}

.editable-project-title-error::-moz-placeholder {  /* Firefox 19+ */
color: #EB635C;
}

.editable-project-title-error:-ms-input-placeholder {
color: #EB635C;
}

@media (max-width: 900px) {
  .editable-project-title {
    font-size: 35px;
    min-height:50px;
    margin-top:10px;

  }

}

.profile-person-photo {
  height:40px;
  width:40px;
  background-color:rgba(216,216,216,1);
  border-radius:30px;
  align-self:center;
  overflow:hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #fff

}

.profile-person-photo img {
  height:100%;
  width:100%;
  object-fit: cover;

}

.profile-picture-letter-small {
  font-size:20px;
  color:#959595;

}

.profile-person-name {
  display:inline-block;
  color:rgba(255,255,255,1);
  font-size:18px;
  font-weight:normal;
  align-self:center;
  margin-left:11px;

}

.profile-person-container {
  display:flex;
  margin:12px auto 12px auto;
  opacity:1;

}

.profile-person-container:hover {
  opacity:0.6;

}

.profile-person {
  display:flex;

}


.project-hero-top-bar {
  position:absolute;
  color:rgba(255,255,255,1.0);
  font-weight: normal;
  font-size:15px;
  margin:57px auto 0 auto;
  width:90%;
  display:flex;
  justify-content: space-between;
}

.back-to-profile-arrow {
  opacity:0.6;

}

.back-to-profile-arrow:hover {
    opacity:1;
    cursor: default;
}

.edit-header-button {
  opacity:0.36;
  display:flex;
}

.edit-header-button:hover {
  opacity:0.5;
  cursor: default;

}

.edit-header-button-icon {
  align-self:center;
  display:inline-block;
}

.edit-header-button-label {
  margin-left: 10px;
  font-size:18px;
  align-self:center;
  display:inline-block;

}

.edit-header-dropdown-container{
  position:absolute;
  margin-left:-40px;
  width:179px;
  overflow:hidden;

}


.edit-header-menu-triangle-container {
  display:flex;
  width:100%;
  justify-content: center;

}

.edit-header-menu-triangle {
  margin-top:8px;
}


.edit-header-dropdown {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 4px 23px 0 rgba(0,0,0,0.07);
  border-radius: 8px;
  width:100%;
  margin-top:-9px;


}

.edit-header-dropdown-row{
  font-size: 18px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;
  border-bottom: 1px solid #EBEDF2;
  padding-top:7px;
  padding-bottom:7px;

}

.edit-header-dropdown-row-image-upload-hover-container:hover {
  opacity:0.6;

}

.edit-header-dropdown-row-image-upload{
  font-size: 18px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;
  position:absolute;


}

.edit-header-dropdown-row-image-upload:input {
  outline: none;

}





.edit-header-dropdown-image-upload {
  margin-left:-300px;
  padding-top:7px;
  padding-bottom:17px;
  padding-right:300px;

}

.edit-header-dropdown-row-image-upload-label {
  position:fixed;


}

.edit-header-dropdown-row:hover {
  opacity:0.6;

}

.edit-header-dropdown-row-last{
  font-size: 18px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;
  padding-top:7px;
  padding-bottom:7px;

}

.edit-header-dropdown-row-last:hover {
  opacity:0.6;

}



/* for mobile layout */
