@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

@font-face {
font-family: 'Brandon';
src: url('./fonts/Brandon_reg.otf') format('truetype');
font-weight: normal;
font-style: normal;
}
@font-face {
font-family: 'Brandon';
src: url('./fonts/Brandon_reg_it.otf') format('truetype');
font-weight: normal;
font-style: italic;
 }

 @font-face {
 font-family: 'Brandon';
 src: url('./fonts/Brandon_med_it.otf') format('truetype');
 font-weight: bold;
 font-style: italic;
  }

@font-face {
font-family: 'Brandon';
src: url('./fonts/Brandon_med.otf') format('truetype');
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'Brandon';
src: url('./fonts/Brandon_med.otf') format('truetype');
font-weight: bold;
font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Brandon','Josefin Sans','Lato',san-serif;
  cursor: default;

}

::placeholder {
  color: #838383;
}

input {
  box-shadow: none;
}
