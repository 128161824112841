.skill-block {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  width:252px;
  display:inline-block;
  margin-left:5px;
  margin-right:5px;
  margin-top:10px;
  font-size: 16px;
}

.skill-block-action-bar {
  border-bottom: 1px solid #EBEDF2;
  height: 26px;
  display:flex;


}

.skill-block-action-bar-actions-container {
  margin: auto 0 auto auto;
  padding-right:18px;


}

.actions-container-vertical-center {

  display:flex;
}

.skill-block-actions {
  margin: auto 0 auto 10px;
  opacity:1;

}

.skill-block-actions-no-hover {
  margin: auto 0 auto 10px;
  opacity:1;

}

.skill-block-actions:hover {
  margin: auto 0 auto 10px;
  opacity:0.6;

}

.skill-block-body-container {
  padding: 14px 17px 14px 17px;

}

.skill-block-skill-name {
  font-weight: 500;


}

.skill-block-display-score {



}

.skill-block-display-score-bar-container {
  display:flex;
  align-items: center;
  padding-top:1.5px;
  padding-bottom:1.5px;

}

.skill-block-display-score-bar-background {
  height: 9px;
  width:192px;
  background: #EBEDF2;
  border-radius: 4.5px;
  overflow:hidden;
  display:inline-block;
  margin-right: 9px;

}

.skill-block-display-score-bar-percentile {
  background: #348EFF;
  border-radius: 4.5px 0 0 4.5px;
  height:100%;

}

.skill-block-display-score-label {
  display:inline-block;
  font-size:14px;

}

.skill-block-display-label {
  display:inline-block;
  font-size:14px;

}

.waiting-to-assess {
  text-decoration: underline;
  opacity:1;

}

.waiting-to-assess:hover {
  opacity:0.6;

}

.not-yet-reviewable{
  text-decoration: underline;
  opacity:1;

}

.not-yet-reviewable:hover {
  opacity:0.6;

}
