.editor button {
  outline: none;
}

.public-DraftEditor-content {
  padding: 0em;
  height: 100%;
}

.block--selected {
  border: 1px solid #348EFF;
  border-radius: 2px;

}

.toolbar {
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  display: flex;
  border: 1px solid #EBEDF2;
  box-shadow: 0px 5px 28px rgba(0,0,0,.07);
  z-index:500;
  background: #fff;
}

.toolbar__button {
  border: none;
  margin: 0;
  outline: none;
  cursor: pointer;
  padding: 14px 25px;
  font-size: 18px;
  border-right: 1px solid #EBEDF2;
  background: #fff;
  color: inherit;
  font-family:Brandon;
  z-index:3000;
  height:100%;

}

.toolbar__button-small {
  border: none;
  margin: 0;
  outline: none;
  cursor: pointer;
  padding: 14px 25px;
  font-size: 17px;
  border-right: 1px solid #EBEDF2;
  background: #fff;
  color: inherit;
  font-family:Brandon;
  z-index:3000;

}

.bold {
  font-weight: 500;

}

.link-underline-styling {
  text-decoration: underline;
}

.toolbar input {
  font-size: 17px;
  outline: none;
  border: none;
  padding: 0px 25px;
  font-family:Brandon;
}

.toolbar__button svg {
  height: 1.3em;
  display: block;
}

.toolbar__button:last-child {
  border-right: none;
}

.toolbar__button--active {
  color: #348EFF;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.paragraph {
  position: relative;
}

.insert-link-placeholder {
  position: absolute;
  top: 0;
  user-select: none;
  left: 0;
  color: #838383;
  pointer-events: none;
}


.insert-link-instructions,
.insert-link-error {
  color: #838383;
  /* font-style: italic; */
  user-select: none;
  padding: .5em 0;
  line-height: 1em;
  pointer-events: none;
  font-size: .7em;
}

.insert-link-error {
  font-style: normal;
  color: #EB635C;
}

[contentEditable] {
  outline: none;
}

.insert-link-input {
  background: #fff;
  font-size: inherit;
  display: block;
  width: 100%;
  border: none;
  padding: 0;
  line-height: inherit;
  outline: none;
  font-family:Brandon;
  resize: none;

}

.key-press {
  font-family: courier;
  font-size: .9em;
  border: 1px solid #ccc;
  padding: .15em .3em .15em;
  margin: 0em .1em;
  font-style: normal;
  display: inline-block;
  border-radius: 2px;
}

.editor-embed__title {
  font-size: 1em;
  margin: 0;
  font-weight:500;
}

.editor-embed__thumbnail-container {
  overflow:hidden;
  /* width:180px; */
  display:flex;
  border-right: 1px solid #EBEDF2;
  max-width:25%;

}

.editor-embed__thumbnail {
  display:flex;
  object-fit:cover;
  margin-top:auto auto auto auto;
  opacity:1;
  width:100%;
  height:180px;

}

@media (max-width:700px) {
  .editor-embed__thumbnail-container {
    display:none;

  }


}

.editor-embed {
  border: 1px solid #eee;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  align-items: center;
  max-height:180px;
  cursor: pointer;
}


.editor-embed.block--selected {
  border-color: #348EFF;
}

.editor-embed__link {
  padding-top: 3px;
  font-size: .8em;
  color: #348EFF;
}

.editor-embed__text {
  padding: 2em;
}

.editor a {
  text-decoration: none;
  color: #348EFF;
}

.editor {
  font-size: inherit;
  font-family: Brandon;
  max-width: 900px;
  /* line-height: 1.4em; */
  display:flex;
  flex-basis:900px;
  color:#242424
}

.editor figure {
  margin: 0;
  padding: 0;
}


.public-DraftEditorPlaceholder-root { 
  width: 100%;
}

.public-DraftEditorPlaceholder-inner,
.block {
  position: relative;
  max-width: 700px;
  margin: auto;
  margin-bottom: 1.5em;
}

.block-video {
  max-width:700px;
  margin: auto;
  margin-bottom: 1.5em;
  position:relative;
  height:0;
  overflow:hidden;
  /* padding-bottom:56.25% */
  padding-bottom:43.75%;

}

@media(max-width:900px){
  .block-video {
    padding-bottom:56.25%
    /* padding-bottom:43.75%; */

  }

}

.editor iframe,
.editor img {
  max-height: 600px;
  display: block;
}

.block--image {
  justify-content: center;
}

.block--image img {
  max-width: 100%;
  max-height: 600px;
  margin: auto;
}

.block--large {
  max-width: 900px;
}

.block--large img {
  width: 100%;
  max-height: none;
}

.image-size-buttons {
  position: absolute;
  display: flex;
  width: 100%;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}


.image-size-buttons .toolbar {
  margin-top: -3em;
}

.image-size-buttons > button {
  width: auto;
}

.editor-link {
  display: inline-block;
  position: relative;
  flex-direction: row;
  text-decoration: underline;
}

.editor-link__popover {
  display: none;
  position: absolute;
  left: 0;
  top: 1.2em;
  z-index: 9999;
  padding-top: .8em;
  max-width:200px;
  align-content: center;
  align-self: center;
  justify-content: center;
}

.editor-link__popover > a {
  background: #333;
  padding: .7em 1em;
  color: #fff;
  font-size: .8em;
  white-space: normal;
  border-radius: 5px;
  position: relative;
}


.editor-link__popover > a:after {
  content: ' ';
  border: 18px solid;
  border-top-width: 0;
  border-color: transparent;
  color: #fff;
  border-bottom-color: #333;
  position: absolute;
  left: calc(50% - 18px);
  top: -10px;
}

.editor-link:hover .editor-link__popover {
  display: flex;
}

iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
