.review-lander-top-bar {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  position:fixed;
  z-index:1;
  background:#FFF;
}

.review-lander-top-bar-container{
  margin-left:25px;
  margin-right:25px;
}


.review-lander-top-section{
  margin-left:25px;
  margin-right:25px;
  padding-top:133px;
}

@media (max-width:600px) {
  .review-lander-top-section{
    padding-top:112px;
  }

}


.review-lander-back-to-profile {
  margin-left:4vw;
  color:#767676;
  font-size:14px;
  opacity:1;
  position:absolute;
  margin-top:10px;
}

.review-lander-back-to-profile:hover {
  opacity:0.6;

}


.review-lander-back-to-profile-label{
  margin-left:5px;
  display:inline-block;

}

.review-lander-back-to-profile-label-mobile{
  margin-left:5px;
  display:none;
}

@media (max-width: 600px) {
  .review-lander-back-to-profile-label{
    margin-left:5px;
    display:none;

  }

  .review-lander-back-to-profile-label-mobile{
    display:inline-block;

  }

  .review-lander-top-bar-container{
    margin-left:10px;
    margin-right:10px;
  }
}




.review-lander-profile-person-photo {
  height:40px;
  width:40px;
  min-width:40px;
  background-color:rgba(216,216,216,1);
  border-radius:20px;
  align-self:center;
  overflow:hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.review-lander-profile-person-photo img {
  height:100%;


}

.review-lander-profile-picture-letter-small {
  font-size:20px;
  color:#959595;

}

.review-lander-profile-person-name {
  display:inline-block;
  color:#242424;
  font-size:18px;
  font-weight:normal;
  align-self:center;
  margin-left:11px;

}

.review-lander-profile-person-container {
  display:flex;
}

.review-lander-profile-person-container:hover {
  display:flex;
  opacity:0.6;
}


.review-lander-profile-person {
  display:flex;
  justify-content: center;
  width:100%;
  opacity:1;

}



.review-lander-skill-name-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-bottom:10px;
}

.review-lander-skill-name {
  font-weight: 500;
  font-size: 50px;
  color: #242424;
  text-align: center;
}

@media (max-width: 600px) {
  .review-lander-skill-name {
    font-size: 35px;
  }

  .review-lander-profile-person-name {
    display:inline-block;
    color:#242424;
    font-size:18px;
    font-weight:normal;
    align-self:center;
    margin-left:11px;

  }

}

.review-lander-description-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-top:18px;

}

.review-lander-description {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;
  max-width:900px;
}

.review-lander-description-blue-span {
  font-weight:500;
  color: #348EFF;
}

.review-lander-divider-container{
  display:flex;
  justify-content: center;
  width: 100%;
}

.review-lander-divider {
  height:1px;
  width:100%;
  background-color: #EBEDF2;
  margin-top:90px;
  margin-bottom:60px;

}


.review-lander-cta-button-container {
  display:flex;
  justify-content: center;

}

.review-lander-cta-button {
  width:256px;
  height:56px;
  background: #348EFF;
  border-radius: 7.2px;
  margin-top:46px;
  display:flex;
  align-items: center;
  justify-content: center;

}

.review-lander-cta-button:hover {
  background: #69ABFF;

}

.review-lander-cta-button-label {
  font-weight:400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;

}

.review-lander-faq-section{
  background: #FAFBFE;
  border: 1px solid #EBEDF2;
  padding-top:83px;
  padding-bottom:183px;
  margin-top:100px;

}

.review-lander-faq-section-container-margin {
  padding-right:15px;
  padding-left:15px;
}

.review-lander-faq-section-container {
  width:100%;
}

.review-lander-faq-section-title{
  font-weight: 500;
  font-size: 35px;
  color: #242424;
  text-align: center;
  width:100%;
  margin-bottom:29px;

}

.review-lander-faq-section-cards {
  display:flex;
  width:100%;
  justify-content: center;
}
