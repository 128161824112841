.image {
  width: 192px;
  height:130px;
  overflow:hidden;
  background: #D8D8D8;
  border-radius: 3px;
  align-self:center;
  margin-left:0px;
}

.image img {
  width:120%;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.image img:hover {
  transform: scale(1, 1);

}
