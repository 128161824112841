.top-bar-branding {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-top: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  background:#FFF;
  height:20px;
  position:fixed;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  opacity:1;
  visibility: visible;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  bottom:0;
  z-index: 100000;

}

.top-bar-branding-cta{
  font-size: 16px;
  color: #348EFF;
}

.top-bar-branding-cta:hover{
  opacity:0.6;
}

.top-bar-branding-pushdown{
  width:100%;
  top:0;
  height:40px;
}
