@import url(https://fonts.googleapis.com/css?family=Lato:300,300i,400);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);

textarea::-webkit-input-placeholder {
color: #838383;
}

textarea:-moz-placeholder { /* Firefox 18- */
color: #838383;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
color: #838383;
}

textarea:-ms-input-placeholder {
color: #838383;
}

.loading-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.5);
  z-index: 7000;
  width:100%;
  top:0;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.general-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.7);
  z-index: 4000;
  width:100%;
  top:0;
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.loading-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  padding: 120px 0;
  z-index: 7001;
}

body {
    position: relative;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.project-hero {
  height:405px;
  width:100%;
  overflow:hidden;
  position:relative;
}

.hero-background-image {
  width:100%;
  height:100%;
  position:absolute;
  z-index:-2;
  object-fit:cover;
  margin-top:auto auto auto auto;
}

.hero-temp-background-image {
  width:100%;
  height:100%;
  position:absolute;
  z-index:-1;
  object-fit:cover;
  margin-top:auto auto auto auto;
}

.project-hero-container {
  background-color:rgba(0,0,0,0.73);
  display:flex;
  height:100%;
  margin-top:0;
  z-index:0;
  justify-content: center;

}

.project-hero-group-container {
  margin-left:25px;
  margin-right:25px;
  display:flex;
  width:814px;
  justify-content: center;
  align-items: center;
}

.project-hero-group {
  width:100%;

}


.editable-project-title {
  min-height:81px;
  font-weight:500;
  width:100%;
  font-family: Brandon;
  font-size: 60px;
  border: none;
  color: rgb(255, 255, 255);
  text-align: center;
  overflow: none;
  outline: none;
  background-color: transparent;
  resize: none;
  box-shadow: none;

}

.editable-project-title-error {
  min-height:81px;
  font-weight:500;
  width:100%;
  font-family: Brandon;
  font-size: 60px;
  border: none;
  color: rgb(255, 255, 255);
  text-align: center;
  overflow: none;
  outline: none;
  background-color: transparent;
  resize: none;
  box-shadow: none;

}

.editable-project-title-error::-webkit-input-placeholder {
  color:#EB635C;
  opacity:0.8;
}

.editable-project-title-error:-moz-placeholder { /* Firefox 18- */
color: #EB635C;
}

.editable-project-title-error::-moz-placeholder {  /* Firefox 19+ */
color: #EB635C;
}

.editable-project-title-error:-ms-input-placeholder {
color: #EB635C;
}

@media (max-width: 900px) {
  .editable-project-title {
    font-size: 35px;
    min-height:50px;
    margin-top:10px;

  }

}

.profile-person-photo {
  height:40px;
  width:40px;
  background-color:rgba(216,216,216,1);
  border-radius:30px;
  align-self:center;
  overflow:hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #fff

}

.profile-person-photo img {
  height:100%;
  width:100%;
  object-fit: cover;

}

.profile-picture-letter-small {
  font-size:20px;
  color:#959595;

}

.profile-person-name {
  display:inline-block;
  color:rgba(255,255,255,1);
  font-size:18px;
  font-weight:normal;
  align-self:center;
  margin-left:11px;

}

.profile-person-container {
  display:flex;
  margin:12px auto 12px auto;
  opacity:1;

}

.profile-person-container:hover {
  opacity:0.6;

}

.profile-person {
  display:flex;

}


.project-hero-top-bar {
  position:absolute;
  color:rgba(255,255,255,1.0);
  font-weight: normal;
  font-size:15px;
  margin:57px auto 0 auto;
  width:90%;
  display:flex;
  justify-content: space-between;
}

.back-to-profile-arrow {
  opacity:0.6;

}

.back-to-profile-arrow:hover {
    opacity:1;
    cursor: default;
}

.edit-header-button {
  opacity:0.36;
  display:flex;
}

.edit-header-button:hover {
  opacity:0.5;
  cursor: default;

}

.edit-header-button-icon {
  align-self:center;
  display:inline-block;
}

.edit-header-button-label {
  margin-left: 10px;
  font-size:18px;
  align-self:center;
  display:inline-block;

}

.edit-header-dropdown-container{
  position:absolute;
  margin-left:-40px;
  width:179px;
  overflow:hidden;

}


.edit-header-menu-triangle-container {
  display:flex;
  width:100%;
  justify-content: center;

}

.edit-header-menu-triangle {
  margin-top:8px;
}


.edit-header-dropdown {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 4px 23px 0 rgba(0,0,0,0.07);
  border-radius: 8px;
  width:100%;
  margin-top:-9px;


}

.edit-header-dropdown-row{
  font-size: 18px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;
  border-bottom: 1px solid #EBEDF2;
  padding-top:7px;
  padding-bottom:7px;

}

.edit-header-dropdown-row-image-upload-hover-container:hover {
  opacity:0.6;

}

.edit-header-dropdown-row-image-upload{
  font-size: 18px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;
  position:absolute;


}

.edit-header-dropdown-row-image-upload:input {
  outline: none;

}





.edit-header-dropdown-image-upload {
  margin-left:-300px;
  padding-top:7px;
  padding-bottom:17px;
  padding-right:300px;

}

.edit-header-dropdown-row-image-upload-label {
  position:fixed;


}

.edit-header-dropdown-row:hover {
  opacity:0.6;

}

.edit-header-dropdown-row-last{
  font-size: 18px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;
  padding-top:7px;
  padding-bottom:7px;

}

.edit-header-dropdown-row-last:hover {
  opacity:0.6;

}



/* for mobile layout */

.image {
  width: 192px;
  height:130px;
  overflow:hidden;
  background: #D8D8D8;
  border-radius: 3px;
  align-self:center;
  margin-left:0px;
}

.image img {
  width:120%;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.image img:hover {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);

}

.edit-header-action-bar {
  background: #404040;
  border-bottom: 1px solid #333333;
  height:64px;
  width:100%;

}

.edit-header-action-bar-container {
  width:100%;
  height:100%;
  display:flex;
  justify-content: flex-end;
  align-items: center;

}

.edit-header-action-bar-button-container {
  display:flex;
  margin-right:5vw;

}

@media (max-width: 600px) {
  .edit-header-action-bar-button-container {
    margin-right:0;

  }

  .edit-header-action-bar-container {
    justify-content: center;

  }

}

.edit-header-action-bar-apply-button {
  background: #348EFF;
  border-radius: 9px;
  width:126px;
  height:36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left:18px;

}

.edit-header-action-bar-apply-button:hover {
  opacity:0.7;

}

.edit-header-action-bar-apply-button-label {
  font-size: 18px;
  color: #FFFFFF;
  text-align:center;
  margin-bottom:1px;
}

.edit-header-action-bar-cancel-button {
  opacity: 0.6;
  border: 1px solid #EBEDF2;
  border-radius: 9px;
  width:126px;
  height:36px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.edit-header-action-bar-cancel-button:hover {
  opacity:0.4;

}

.edit-header-action-bar-cancel-button-label {
  font-size: 18px;
  color: #FFFFFF;
  text-align:center;
  margin-bottom:1px;
}


.gallery {
  overflow:hidden;
  height:178px;
  background-color:rgba(250,251,254,1);
  border-bottom: 1px solid rgba(235,237,242,1);
  position:absolute;
  width:100%;
  overflow-x:hidden;
  z-index:1;
}

.gallery-container {
  width:100%;
  height:178px;
  display:grid;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom:20px;
}

.image-wrapper {
  width: 192px;
  height:130px;
  overflow:hidden;
  background: #D8D8D8;
  border-radius: 3px;
  align-self:center;
  margin-left:10px;
  flex: 0 0 auto;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  cursor: pointer;
}

.image-wrapper:hover {
  -webkit-transform: scale(1.02, 1.02);
          transform: scale(1.02, 1.02);
  box-shadow: 0 6px 16px rgba(0,0,0, 0.2);

}

.gallery-overlay {
  width:100%;
  background:#FFFFFF;
  opacity:0.9;
  position:absolute;
  height:calc(100% - 405px);

}

.command-area {
  cursor:default;
  position:fixed;
  z-index:1000;
  width:100%;
  overflow: hidden;
  pointer-events:none;
}

.command-bar {
  width:100%;
  height:36px;
  background-color:rgba(64,64,64,1.0);
  border-bottom:1px solid rgba(51,51,51,1.0);
  overflow:hidden;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  pointer-events:auto;
}

.command-bar-hidden {
  width:100%;
  height:36px;
  background-color:rgba(64,64,64,1.0);
  border-bottom:1px solid rgba(51,51,51,1.0);
  overflow:hidden;
  z-index:1000;
  margin-top: -31px;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.command-bar-container {
  display:flex;
  justify-content: space-between;
}

.command-bar-left-container {
  display:inline-block;
}

.command-bar-right-container {
  display:flex;
}

.hide-command-bar-block {
  height:36px;
  width: 38px;
  border-right:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-command-bar-arrow-container {
  opacity:0.53;
}

.hide-command-bar-arrow-container:hover {
  opacity:1.0;
}

.hide-command-bar-arrow {
  align-self:center;
  display:inline-block;
  align-self:center;
  display:inline-block;
  margin: auto auto auto auto;

}

.general-block-container {

}

.dashboard-block {
  color:rgba(255,255,255,1);
  font-size:14px;
  height:36px;
  width: 120px;
  border-left:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content: center;

}

.dashboard-block-container {
  opacity:0.53;
  display: flex;
  align-items: center;
}

.dashboard-block-container:hover {
  opacity:1.0;
}

.dashboard-grid-icon {
  display:inline-block;

}

.dashboard-block-label {
  display:inline-block;
  margin-left:8px;
}

.preview-block {
  color:rgba(255,255,255,1);
  font-size:14px;
  height:36px;
  width: 120px;
  border-left:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content:center;

}

.preview-block-container {
  opacity:0.53;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-block-container:hover {
  opacity:1.0;
}

.preview-eye-icon {
  display:inline-block;

}

.preview-block-label {
  display:inline-block;
}



.saved-block {
  height:36px;
  width: 38px;
  border-left:1px solid rgba(51,51,51,1.0);
  display:flex;
  justify-content:center;
}

.saved-block-container {
  display: flex;
  align-items: center;

}

.saved-successful-badge {
}


.publish-block {
  color:rgba(255,255,255,1);
  font-size:14px;
  height:36px;
  border-left:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content: center;

}

.publish-block-container {
  opacity:0.53;
  display: flex;
  align-items: center;
  padding-left:17px;
  padding-right:17px;
}

.publish-block-container:hover {
  opacity:1.0;
}

.publish-airplane-icon {
  display:inline-block;

}

.publish-edits-icon-container {
  display:inline-block;
  flex-direction: row;
  margin-top: 2px;
  margin-right: 5px;
  margin-left: -2px;
  opacity: 1.0 !important;
}

.publish-edits-icon {
  display:inline-block;
}

.publish-block-label {
  display:inline-block;
  margin-left:8px;
  margin-right:8px;
}

.edit-block-label {
  display:inline-block;
  margin-left:8px;
}

.publish-dropdown-arrow {
  display:inline-block;
}

.publish-block-container-open {
  opacity:1.0;
  display: flex;
  align-items: center;
  padding-left:17px;
  padding-right:17px;


}

.command-publish-menu {
  z-index:1001;
  display:flex;
  margin-top:-8px;

}

.command-publish-menu-container {
  display:inline-block;
  margin-left: auto;
  margin-right: 7px;

}

.command-bar-tab {
  background-color:rgba(64,64,64,1.0);
  border-bottom:1px solid rgba(51,51,51,1.0);
  border-left:1px solid rgba(51,51,51,1.0);
  border-right:1px solid rgba(51,51,51,1.0);
  width: 26px;
  height:24px;
  margin-left:9px;
  margin-top:6px;
  border-radius: 0 0 3px 3px;
  position:fixed;
  z-index:1;


}

.hide-command-bar-block-tab {
  width: 26px;
  height:24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.publish-menu-container {
  display:inline-block;
  width:100%;
}

.publish-menu-triangle-container {
  display:flex;
}

.publish-menu-triangle {
  margin-left:auto;
  margin-right:35px;

}


.publish-menu {
  background-color:rgba(64,64,64,1.0);
  border:1px solid rgba(51,51,51,1.0);
  height:100%;
  width:455px;
  border-radius:8px;
  color:rgba(255,255,255,1);
  margin-top:-9px;
  pointer-events:auto;
}

@media (max-width: 455px) {
  .publish-menu {
    width:100%;

  }

}

.publish-menu-top-title-bar {
  height:31px;
  width:100%;
  font-size: 11px;
  display:flex;
  align-items: center;
  border-bottom: 1px solid rgba(51,51,51,1.0);
}

.publish-menu-top-title-bar-label {
  text-transform: uppercase;
  margin-left:30px;

}


.unpublished-changes-bar {
  height:40px;
  width:100%;
  font-size: 15px;
  display:flex;
  align-items: center;
  color: rgb(232, 218, 94, 0.9);
  border-bottom: 1px solid rgba(51,51,51,1.0);
}

.publish-edits-icon {
  margin-right: 3px;
}

.unpublished-changes-container {
  margin-left: 25px;
  display:flex;
  align-items: center;
  width: 100%;
}

.publish-status-bar {
  width:100%;
  font-size: 15px;
  display:flex;
  align-items: center;
  border-bottom: 1px solid rgba(51,51,51,1.0);
}

.publish-status-bar-container{
  padding-top: 11px;
  padding-bottom: 11px;

}


.publish-status-bar-project-url-container {
  margin-left:30px;
  display:flex;
  align-items: center;
  width:100%
}


.publish-status-bar-project-url {
  font-size:18px;
  color:rgba(246,246,246,1);
  width:185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display:inline-block;
  margin-right:11px;
}

.open-project-arrow-icon-container {
  display:inline-block;
  opacity:0.53;

}

.open-project-arrow-icon-container:hover {
  display:inline-block;
  opacity:1.0;
}

.open-project-arrow-icon {
  display: flex;
  align-items: center;
  height: 100%;

}

.open-project-arrow-icon-svg {
  display:block;
  height:100%;
  margin:auto;

}

.publish-status-bar-secondary-row-container {
  margin-top:4px;
  margin-left:30px;
  padding-bottom:4px;
  display:block;
  width:100%;

}

.publish-status-bar-publish-state {
  display:inline-block;
  color:rgba(52,142,255,1);

}

.publish-status-bar-publish-state-error {
  display:inline-block;
  color:#EB635C;

}

.publish-status-bar-circle-separator {
  display:inline-block;
  margin:auto 12px auto 12px;
}

.publish-status-bar-circle-separator-container {
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;

}

.publish-status-bar-circle-separator-icon {
  display:inline-block;
  align-self: center;

}

.publish-status-bar-unpublish {
  text-decoration: underline;
  display:inline-block;
  opacity:0.53;
}

.publish-status-bar-unpublish:hover {
  opacity:1.0;
}



.quantified-skills-bar {
  padding-top:11px;
  padding-bottom:11px;
  width:100%;
  font-size: 15px;
  display:flex;
  align-items: center;
  border-bottom: 1px solid rgba(51,51,51,1.0);


}

.quantified-skills-bar-label-container {
  margin-left:30px;
  margin-right:30px;

}


.quantified-skills-bar-label {
  font-size:18px;
  color:rgba(246,246,246,1);
  width:246px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantified-skills-secondary-row-container {
  margin-top:4px;
  padding-bottom:4px;

}

.quantified-skills-privacy-state {
  display:inline-block;
  color:rgba(52,142,255,1);

}

.quantified-skills-state-icon-container {
  display:inline-block;
  margin-right:4px;
}

.quantified-skills-state-icon {
  display:flex;
  align-items: center;
}

.quantified-skills-state-icon.svg {
  margin: auto auto auto auto;
  display: block;

}

.quantified-skills-state-label {
  display:inline-block;

}

.publish-status-bar-circle-separator {
  display:inline-block;
  margin:auto 12px auto 12px;
}

.publish-status-bar-circle-separator-container {
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;

}

.publish-status-bar-circle-separator-icon {
  display:inline-block;
  align-self: center;

}

.quantified-skills-toggle-privacy {
  display:inline-block;
  opacity:0.53
}
.quantified-skills-toggle-privacy:hover{
  opacity:1.0
}

.quantified-skills-body-text {
  display:inline-block;
  opacity:0.53
}

.quantified-skills-learn-more {
  display:inline-block;
  text-decoration: underline;
  opacity:0.53
}

.quantified-skills-learn-more:hover {
  opacity:1.0
}

.optInToQuantifyRow {
display:flex;
align-items: center;

}



.optInToQuantifyPubliclyRowContainer {
  display:flex;
  align-items: center;
  margin-top:8px;
  margin-bottom:5px;
}



.checkBox {
  height:15px;
  width:15px;
  background: #363636;
  border: 1px solid #333333;
  border-radius: 2px;
  display:inline-block;

}

.checkBox:hover {
  background: #303030;

}

.checkMark {
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:3px;
  margin-left:2.5px;
}

.checkBoxLabel {
  display:inline-block;
  margin-left:9px;

}




.publish-menu-buttons {
  height:59px;
  display:flex;
  align-content:center;
  font-size: 15px;
}

.publish-menu-buttons-container {
  margin:auto 0px auto 30px;

}

.publish-menu-publish-button {
  display:inline-block;
  height:30px;
  width:126px;
  background-color: rgba(52,142,255,1);
  color:white;
  border-radius: 8px;
  margin-right:8px;

}

.publish-menu-publish-button-saving {
  display:inline-block;
  height:30px;
  width:126px;
  opacity: 0.6;
  background-color: rgba(52,142,255, 1);
  color:white;
  border-radius: 8px;
  margin-right:8px;

}

.publish-menu-publish-button:hover{
  opacity:0.7;

}

.publish-menu-publish-button-disabled {
  display:inline-block;
  height:30px;
  width:126px;
  background-color: #EB635C;
  color:white;
  border-radius: 8px;
  margin-right:8px;
  opacity:0.6;

}

.publish-menu-publish-button-container {
  display:flex;
  height:100%
}

.publish-menu-publish-button-label {
  margin:auto auto auto auto;
  display:inline-block;

}


.publish-menu-close-button {
  display:inline-block;
  height:30px;
  width:126px;
  background-color: none;
  color:#ADADAD;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #868789 inset;

}

.publish-menu-close-button:hover {
  opacity:0.7;

}

.publish-menu-close-button-container {
  display:flex;
  height:100%
}

.publish-menu-close-button-label {
  margin:auto auto auto auto;
  display:inline-block;

}

.publish-menu-close-button {
  display:inline-block;

}

.generic-modal-overlay{
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.7);
  z-index: 5000;
  width:100%;
  top:0;
  left:0;
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.generic-modal-container {
  margin-top:5VH;
  margin-left: 10px;
  margin-right:10px;
}

.generic-modal {
  margin:0 auto 0 auto;
  font-size:18px;
  background: #FFFFFF;
  border-radius: 8px;
  display:flex;
  flex-basis: 600px;
  max-width:600px;
}

.generic-modal-responsive-container{
  width:100%;


}

.generic-modal-top-bar {
  height:33px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.generic-modal-x-container {
  padding-right:20px;
  padding-top:15px;

}

.generic-modal-x {
  opacity:0.5;

}

.generic-modal-x:hover {
  opacity:1;

}

.generic-modal-title-body {
  margin-top:17px;
  width:100%;

}

.generic-modal-title-container {
  display:flex;
  margin-left:100px;
  margin-right:100px;

}

.generic-modal-title {

}

.generic-modal-title-label {
  font-size: 30px;
  font-weight: 500;
  text-align:left;
  width:100%;
}



.generic-modal-scroll-container {
  overflow-y:auto;
  overflow-x:hidden;
  max-height: 50vh;
}


.generic-modal-section {
  width:100%;
}

.generic-modal-section-container {
  height:100%;
  padding-left:100px;
  padding-right:100px;
  padding-top:10px;
  padding-bottom:58px;
  color:#5C5C5C;
  font-size:18px;


}


.generic-modal-section-row{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:16px;

}

.generic-modal-section-column-padding-right {
  width:100%;
  margin-right: 17px;

}

.generic-modal-section-column{
  width:100%;


}


@media (max-width: 600px) {
  .generic-modal-section-row{
    display:block;
    align-items: center;
    justify-content: space-between;
    width:100%;


  }

  .generic-modal-section-column {
    padding-top:16px;


  }

  .generic-modal-section-row{
    padding-top:0px;

  }

  .generic-modal-section-container {
    height:100%;
    padding-left:50px;
    padding-right:50px;

  }

  .generic-modal-title-container {
    display:flex;
    margin-left:50px;
    margin-right:50px;

  }

}

.generic-modal-half-field-container {
  display:flex;
  width:100%;

}

.generic-modal-field-container {
  display:flex;
  width:100%;

}

.generic-modal-half-field {
  height:65px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}



.generic-modal-field {
  height:65px;
  width:436px;
  padding:auto 31 auto 31;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}


.generic-accept-message {
  font-size:12px;
  text-align: center;
  color:#ABABAB;
  margin-top:3px;

}

.generic-modal-error-message {
  font-size:13px;
  text-align: center;
  color:#EF7879;
  margin-top:3px;
    -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

}

.generic-modal-login-option {
  text-align: center;
  font-size: 13px;
  color:#ABABAB;
  display:flex;
  justify-content: center;
  margin-left:50px;
  margin-right:50px;
  margin-top:10px;
  margin-bottom: 10px;



}

.blue-log-in {
  color:#348EFF;
  opacity:1;

}

.blue-log-in:hover {
  opacity:0.6;
}

.generic-modal-button-section {
  display:flex;
  border-top: 1px solid #EBEDF2;

}

.generic-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: center;
  font-size: 18px;
}


.generic-modal-primary-button {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
}

.generic-modal-primary-button-waiting {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}

.generic-modal-primary-button:hover {
  opacity:0.6;


}

.generic-modal-primary-button-label {
  margin-top:13px;

}

.generic-modal-secondary-button {
  height:52px;
  width:169px;
  text-align: center;
  display:inline-block;
  background-color: none;
  color:#5C5C5C;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #DCDCDC inset;
  opacity:1;
  margin-right:10px;
}

.generic-modal-secondary-button:hover {
  opacity:0.6;


}

.generic-modal-secondary-button-label {
  margin-top:13px;

}

.generic-modal-underline-blue{
  color:#348EFF;
  text-decoration:underline;

}

.generic-modal-text-bold{
  font-weight: 500;
  color:#242424;

}

.project-details{
  height:100%;
  margin-bottom:100px;
  font-size:22px;
  line-height: 1.4;
}

.project-details-container {
  margin-left:25px;
  margin-right:25px;
}

.project-details-section {
  margin-top:84px;
  width:100%;


}

.project-details-section-title-container{
  margin-bottom:25px;
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-section-title {
  font-weight: 500;
  font-size: 35px;
  color: #242424;
  letter-spacing: 0;
  width:700px;

}

.project-details-section-textarea-container{
  margin-bottom:40px;
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-section-textarea {
  font-size: 22px;
  color: #242424;
  font-family: Brandon;
  letter-spacing: 0;
  width:700px;
  min-height:31px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  box-shadow: none;
}

.project-details-section-textarea-for-draft {
  font-size: 22px;
}


.project-details-section-textarea-error {
  font-size: 22px;
  color: #242424;
  font-family: Brandon;
  letter-spacing: 0;
  width:700px;
  min-height:31px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  box-shadow: none;
}

.project-details-section-textarea-error::-webkit-input-placeholder {
  color:#EB635C;
  opacity:0.8;
}

.project-details-section-textarea-error:-moz-placeholder { /* Firefox 18- */
color: #EB635C;
}

.project-details-section-textarea-error::-moz-placeholder {  /* Firefox 19+ */
color: #EB635C;
}

.project-details-section-textarea-error:-ms-input-placeholder {
color: #EB635C;
}



.project-details-section-metadata-row-container{
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-section-metadata-row {
  width:700px;
  border-top: solid 1px #EBEDF2;
  border-bottom: solid 1px #EBEDF2;
  margin-top:-1px;
  font-size:18px;
  padding-top:19px;
  padding-bottom:19px;

}


.project-details-section-metadata-row-flex-container {
  display:flex;
  width:100%;

}

.project-details-section-metadata-row-label{
  width:156px;
  font-weight: 500;

}

.project-details-section-metadata-row-textarea{
  width:100%;
  height:26px;
  font-family: Brandon;
  font-size: 18px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  padding:0 0 0 10px;
  box-shadow: none;
}

.project-details-section-metadata-row-textarea-error{
  width:100%;
  height:26px;
  font-family: Brandon;
  font-size: 18px;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  padding:0 0 0 10px;
  box-shadow: none;
  color:#EB635C;
}



.project-details-section-metadata-row-textarea-error::-webkit-input-placeholder {
  color:#EB635C;
  opacity:0.8;
}

.project-details-section-metadata-row-textarea-error:-moz-placeholder { /* Firefox 18- */
color: #EB635C;
}

.project-details-section-metadata-row-textarea-error::-moz-placeholder {  /* Firefox 19+ */
color: #EB635C;
}

.project-details-section-metadata-row-textarea-error:-ms-input-placeholder {
color: #EB635C;
}












/* Skills area */

.project-details-skill-area-flex-container{
  display:flex;


}

.project-details-skill-area {
  width:900px;
  background: #FFFFFF;
  border: 1px solid #F7F8FA;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
  margin: 50px auto 0 auto;

}

.project-details-skill-area-container {
  margin-left:25px;
  margin-right:25px;


}

.project-details-skill-area-label {
  margin-top: 41px;
  font-weight: 500;
  font-size:35px;
}

.project-details-skill-area-body {
  margin-top:15px;
  font-size:18px;

}

.underline {
  text-decoration:underline;
  opacity:1;

}

.underline:hover {
  opacity:0.6;

}

.skill-area-divider {
  border-bottom: 1px solid #EBEDF2;

}

.add-skill-button {
  color: #348EFF;
  text-align: center;
  opacity:1;
  padding-top:20px;
  padding-bottom:20px;
  font-size:18px;
}

.add-skill-button:hover {
  opacity:0.6;
}

.skills-container {
  display:flex;
  flex-flow: row wrap;
  margin-top:15px;
  margin-bottom:48px;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;

}

.project-details-skill-area-text-container{
  display:flex;
  width:100%;
  justify-content: center;

}

.project-details-skill-area-text{
  max-width:700px;
  display:flex;
  flex-basis:700px;
}

.skill-block {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  width:252px;
  display:inline-block;
  margin-left:5px;
  margin-right:5px;
  margin-top:10px;
  font-size: 16px;
}

.skill-block-action-bar {
  border-bottom: 1px solid #EBEDF2;
  height: 26px;
  display:flex;


}

.skill-block-action-bar-actions-container {
  margin: auto 0 auto auto;
  padding-right:18px;


}

.actions-container-vertical-center {

  display:flex;
}

.skill-block-actions {
  margin: auto 0 auto 10px;
  opacity:1;

}

.skill-block-actions-no-hover {
  margin: auto 0 auto 10px;
  opacity:1;

}

.skill-block-actions:hover {
  margin: auto 0 auto 10px;
  opacity:0.6;

}

.skill-block-body-container {
  padding: 14px 17px 14px 17px;

}

.skill-block-skill-name {
  font-weight: 500;


}

.skill-block-display-score {



}

.skill-block-display-score-bar-container {
  display:flex;
  align-items: center;
  padding-top:1.5px;
  padding-bottom:1.5px;

}

.skill-block-display-score-bar-background {
  height: 9px;
  width:192px;
  background: #EBEDF2;
  border-radius: 4.5px;
  overflow:hidden;
  display:inline-block;
  margin-right: 9px;

}

.skill-block-display-score-bar-percentile {
  background: #348EFF;
  border-radius: 4.5px 0 0 4.5px;
  height:100%;

}

.skill-block-display-score-label {
  display:inline-block;
  font-size:14px;

}

.skill-block-display-label {
  display:inline-block;
  font-size:14px;

}

.waiting-to-assess {
  text-decoration: underline;
  opacity:1;

}

.waiting-to-assess:hover {
  opacity:0.6;

}

.not-yet-reviewable{
  text-decoration: underline;
  opacity:1;

}

.not-yet-reviewable:hover {
  opacity:0.6;

}

.loading-spinner {
  width: 70px;
  margin-left: -1em;
  text-align: center;
}

.loading-spinner > div {
  width: 10px;
  height: 10px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loading-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loading-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.side-toolbar {
  position: absolute;
  top: -.1em;
  left: -2.5em;
}

.side-toolbar-toggle {
  border: 1px solid #EBEDF2;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  height:36px;
  width:37px;
  padding-top:2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-toolbar-toggle > * {
  display: inline-block;
  -webkit-transition: 200ms;
  transition: 200ms;
  font-weight: bold;
}

.side-toolbar-toggle.expanded > * {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.side-toolbar-buttons {
  position: absolute;
  top: -.45em;
  left: 2.5em;
  border: 1px solid #EBEDF2;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
}

.side-toolbar__button {
  border: none;
  cursor: pointer;
  -webkit-transition: 200ms;
  transition: 200ms;
  background: #fff;
  line-height: 1em;
  position: relative;
  border-right: 1px solid #EBEDF2;
  align-self: stretch;
  width:62px;
  display: flex;
  justify-content: center;
  align-items: center;
  height:55px;
}



.side-toolbar__button:hover {
  /* background: #FAFBFE; */
}

.side-toolbar__button svg {
  vertical-align: middle;
  -webkit-transform:scale(0.85);
          transform:scale(0.85);
}

.side-toolbar__button:first-child {
}

.side-toolbar__button:last-child {
  border-right: none;
}

.side-toolbar__button input[type="file"] {
  position: absolute;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.editor button {
  outline: none;
}

.public-DraftEditor-content {
  padding: 0em;
  height: 100%;
}

.block--selected {
  border: 1px solid #348EFF;
  border-radius: 2px;

}

.toolbar {
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  display: flex;
  border: 1px solid #EBEDF2;
  box-shadow: 0px 5px 28px rgba(0,0,0,.07);
  z-index:500;
  background: #fff;
}

.toolbar__button {
  border: none;
  margin: 0;
  outline: none;
  cursor: pointer;
  padding: 14px 25px;
  font-size: 18px;
  border-right: 1px solid #EBEDF2;
  background: #fff;
  color: inherit;
  font-family:Brandon;
  z-index:3000;
  height:100%;

}

.toolbar__button-small {
  border: none;
  margin: 0;
  outline: none;
  cursor: pointer;
  padding: 14px 25px;
  font-size: 17px;
  border-right: 1px solid #EBEDF2;
  background: #fff;
  color: inherit;
  font-family:Brandon;
  z-index:3000;

}

.bold {
  font-weight: 500;

}

.link-underline-styling {
  text-decoration: underline;
}

.toolbar input {
  font-size: 17px;
  outline: none;
  border: none;
  padding: 0px 25px;
  font-family:Brandon;
}

.toolbar__button svg {
  height: 1.3em;
  display: block;
}

.toolbar__button:last-child {
  border-right: none;
}

.toolbar__button--active {
  color: #348EFF;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.paragraph {
  position: relative;
}

.insert-link-placeholder {
  position: absolute;
  top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  left: 0;
  color: #838383;
  pointer-events: none;
}


.insert-link-instructions,
.insert-link-error {
  color: #838383;
  /* font-style: italic; */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: .5em 0;
  line-height: 1em;
  pointer-events: none;
  font-size: .7em;
}

.insert-link-error {
  font-style: normal;
  color: #EB635C;
}

[contentEditable] {
  outline: none;
}

.insert-link-input {
  background: #fff;
  font-size: inherit;
  display: block;
  width: 100%;
  border: none;
  padding: 0;
  line-height: inherit;
  outline: none;
  font-family:Brandon;
  resize: none;

}

.key-press {
  font-family: courier;
  font-size: .9em;
  border: 1px solid #ccc;
  padding: .15em .3em .15em;
  margin: 0em .1em;
  font-style: normal;
  display: inline-block;
  border-radius: 2px;
}

.editor-embed__title {
  font-size: 1em;
  margin: 0;
  font-weight:500;
}

.editor-embed__thumbnail-container {
  overflow:hidden;
  /* width:180px; */
  display:flex;
  border-right: 1px solid #EBEDF2;
  max-width:25%;

}

.editor-embed__thumbnail {
  display:flex;
  object-fit:cover;
  margin-top:auto auto auto auto;
  opacity:1;
  width:100%;
  height:180px;

}

@media (max-width:700px) {
  .editor-embed__thumbnail-container {
    display:none;

  }


}

.editor-embed {
  border: 1px solid #eee;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  align-items: center;
  max-height:180px;
  cursor: pointer;
}


.editor-embed.block--selected {
  border-color: #348EFF;
}

.editor-embed__link {
  padding-top: 3px;
  font-size: .8em;
  color: #348EFF;
}

.editor-embed__text {
  padding: 2em;
}

.editor a {
  text-decoration: none;
  color: #348EFF;
}

.editor {
  font-size: inherit;
  font-family: Brandon;
  max-width: 900px;
  /* line-height: 1.4em; */
  display:flex;
  flex-basis:900px;
  color:#242424
}

.editor figure {
  margin: 0;
  padding: 0;
}


.public-DraftEditorPlaceholder-root { 
  width: 100%;
}

.public-DraftEditorPlaceholder-inner,
.block {
  position: relative;
  max-width: 700px;
  margin: auto;
  margin-bottom: 1.5em;
}

.block-video {
  max-width:700px;
  margin: auto;
  margin-bottom: 1.5em;
  position:relative;
  height:0;
  overflow:hidden;
  /* padding-bottom:56.25% */
  padding-bottom:43.75%;

}

@media(max-width:900px){
  .block-video {
    padding-bottom:56.25%
    /* padding-bottom:43.75%; */

  }

}

.editor iframe,
.editor img {
  max-height: 600px;
  display: block;
}

.block--image {
  justify-content: center;
}

.block--image img {
  max-width: 100%;
  max-height: 600px;
  margin: auto;
}

.block--large {
  max-width: 900px;
}

.block--large img {
  width: 100%;
  max-height: none;
}

.image-size-buttons {
  position: absolute;
  display: flex;
  width: 100%;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}


.image-size-buttons .toolbar {
  margin-top: -3em;
}

.image-size-buttons > button {
  width: auto;
}

.editor-link {
  display: inline-block;
  position: relative;
  flex-direction: row;
  text-decoration: underline;
}

.editor-link__popover {
  display: none;
  position: absolute;
  left: 0;
  top: 1.2em;
  z-index: 9999;
  padding-top: .8em;
  max-width:200px;
  align-content: center;
  align-self: center;
  justify-content: center;
}

.editor-link__popover > a {
  background: #333;
  padding: .7em 1em;
  color: #fff;
  font-size: .8em;
  white-space: normal;
  border-radius: 5px;
  position: relative;
}


.editor-link__popover > a:after {
  content: ' ';
  border: 18px solid;
  border-top-width: 0;
  border-color: transparent;
  color: #fff;
  border-bottom-color: #333;
  position: absolute;
  left: calc(50% - 18px);
  top: -10px;
}

.editor-link:hover .editor-link__popover {
  display: flex;
}

iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,.7);
  z-index: 5000;
  width:100%;

}

.add-skill-modal-container {
  display:flex;
  margin-top:69px;
  margin-left: 10px;
  margin-right:10px;
}

.add-skill-modal {
  margin:0 auto 0 auto;
  width:600px;
  background: #FFFFFF;
  border-radius: 8px;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.add-skill-modal-top-bar {
  height:50px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.add-skill-modal-x-container {
  padding-right:20px;
  padding-top:15px;

}

.add-skill-modal-x {
  opacity:0.5;

}

.add-skill-modal-x:hover {
  opacity:1;

}

.add-skill-modal-main-body {

  width:100%;
  border-bottom: 1px solid #EBEDF2;
  padding-bottom:42px;
}

.add-skill-modal-title-container {
  display:flex;

}

.add-skill-modal-title {
  width:390px;
  margin: 0 auto 0 auto;

}

.add-skill-modal-title-label {
  font-size: 30px;
  font-weight: 500;
}

.add-skill-input-field-container {
  display:flex;
  justify-content: center;
  margin-top:30px;

}

.add-skill-input-field-container input{
  width:352px;
  height:65px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}


.new-skill-warning-message-container {
  margin: 20px auto -10px auto;
  display:flex;

}

.new-skill-warning-message {
  margin: 0 auto 0 auto;
  width:70%;
  color: #838383;
  font-size: 14px;


}


.add-skill-modal-button-section {
  display:flex;

}

.add-skill-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: space-between;
  font-size: 18px;
}

.add-skill-modal-close-button {
  height:52px;
  width:169px;
  text-align: center;
  display:inline-block;
  background-color: none;
  color:#5C5C5C;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #DCDCDC inset;
  opacity:1;
}

.add-skill-modal-close-button:hover {
  opacity:0.6;


}

.add-skill-modal-close-button-label {
  margin-top:13px;

}

.add-skill-modal-add-skill-button {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
}

.add-skill-modal-add-skill-button-disabled {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}

.add-skill-modal-add-skill-button:hover {
  opacity:0.6;


}

.add-skill-modal-add-skill-button-label {
  margin-top:13px;

}





/* MOBILE VIEW */

@media (max-width: 600px) {
  .add-skill-modal-title {
    text-align: center;

  }

  .add-skill-modal {




  }

}

.bottom-badge-container {
  width:100%;
  height:100vh;
  position:fixed;
  z-index:10000;
  top:0;
  display:flex;
  justify-content: flex-end;
  align-items:flex-end;
  pointer-events:none;

}


.bottom-badge {
  width:300px;
  height:40px;
  background-color: #FFFFFF;
  border-top: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  /* box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21); */
  z-index: 102;
  pointer-events:auto;
  border-radius: 8px 8px 0 0 ;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#838383;
  font-size:15px;
  margin-bottom:-80px;
  padding-bottom:80px;
  margin-right:10px;
}

@media (max-width:700px) {
  .bottom-badge {
    width:100%;
    margin-right:0px;
    border-radius: 0;

  }

}


textarea::-webkit-input-placeholder {
color: #838383;
}

textarea:-moz-placeholder { /* Firefox 18- */
color: #838383;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
color: #838383;
}

textarea:-ms-input-placeholder {
color: #838383;
}

.loading-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.3);
  z-index: 7000;
  width:100%;
  top:0;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.loading-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  padding: 120px 0;
  z-index: 7001;
}

.profile-header {
  border-bottom: 1px solid #EBEDF2;

}


.profile-header-buttons-main-container {
  position:absolute;
  display:flex;
  justify-content: flex-end;
  width:100%;
  pointer-events:none;
}

.profile-header-buttons-sub-container {
  width:173px;
  margin-right:4.5vw;
  display:flex;
  flex-wrap: wrap;
  pointer-events:auto;
}


.profile-header-button {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  opacity:1;
  margin-bottom:18px;
  /* pointer-events:auto; */

}

@media (max-width: 900px) {
  .profile-header-buttons-main-container{
    position:static;
  }

  .profile-header-buttons-sub-container {
    width:100%;
    margin-right:0;
    position:static;
    display:flex;
    justify-content:space-around;
    margin-top:10px;
  }

  .profile-header-button {
    margin-bottom:10px;

  }

  .profile-header-button:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


  }

}

.profile-header-button:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


}

.profile-header-button-label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;

}

.profile-header-button-icon {
  margin-right:7px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.profile-header-button-label {
  color:#838383;

}

.profile-header-button-label-red {
  color:#348EFF;
  /* color:#EF7879; */
  /* color:#838383; */
}


.profile-header-container {
  display:flex;
  margin-top:38px;
  margin-bottom:65px;
  align-items: center;
  justify-content: center;
}

.profile-header-main-container {
  padding-left: 15px;
  padding-right:15px;


}

.profile-picture-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.profile-picture-border {
  height:120px;
  width:120px;
  background: #FFFFFF;
  border: 1px solid #EFF0F5;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius:60px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.profile-picture {
  background: #D8D8D8;
  height: 111px;
  width:111px;
  border-radius: 55.5px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.profile-picture img {
  height:100%;
  width:100%;
  object-fit: cover;

}

.profile-picture-letter {
  font-size:40px;
  color:#959595;

}

.profile-header-name-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  text-align:center;

}


.profile-header-name {
  font-size: 50px;
  font-weight:500;
  margin-top:16px;
}

.profile-header-bio-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.profile-header-bio {
  font-size:20px;
  font-style: italic;
  max-width:430px;
  text-align:center;
  line-height: 24px;
  margin-top:5px;

}

.profile-header-cta-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.profile-header-cta {
  font-size:20px;
  color:#348EFF;
  text-align:center;
  margin-top:31px;
  opacity:1;
}

.profile-header-cta:hover {
    opacity:0.6;

}

.profile-skills-section {
  width:100%;
  background-color: #FAFBFE;
  border-bottom: 1px solid #EBEDF2;

}

.profile-skills-section-container {
  display:flex;
  padding-top:49px;
  padding-bottom:65px;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right:15px;
}

.profile-skills-section-title-container {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-skills-section-title {
  font-size: 35px;
  font-weight: 500;
}


.profile-skills-section-circle-skill-blocks-container {
  display:flex;
  justify-content: center;
  width:100%;
  margin-top:34px;
  margin-bottom:24px;
  flex-flow: row wrap;
  max-width:1200px;


}

.profile-skills-section-circle-skill-block-container {
  margin-left:15px;
  margin-right:15px;
  margin-bottom:30px

}


.profile-skills-section-quantified-message {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-skills-section-quantified-message-container {
  font-size:16px;
  font-style:italic;
  margin-top:6px;
  text-align:center;
}

.project-skills-underline {
  text-decoration: underline;
  opacity:1;

}

.project-skills-underline:hover {
  opacity:0.6;

}

.profile-skills-section-show-all {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
  margin-top:40px;

}

.profile-skills-section-show-all-container {
  opacity:1;
}

.profile-skills-section-show-all-container:hover {
  opacity:0.6;


}

.profile-skills-section-show-all-text {
  font-size:16px;
  margin-top:6px;
  text-align:center;
  color: #767676;

}

.profile-skills-section-show-all-arrow {
  width:100%;
  display: flex;
  justify-content: center;
  margin-top:9px;

}

.profile-skills-section-show-less-arrow {
  width:100%;
  display: flex;
  justify-content: center;
  margin-bottom:3px;

}




.profile-skills-section-empty-message {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-skills-section-empty-message-container {
  font-size:16px;
  color:#767676;
  margin-top:6px;
  text-align:center;
}

.circle-skill-block {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
  width:200px;


}

.circle-skill-block-title-container {
  width:100%;
  display:flex;
  height:42px;
  justify-content: center;
  margin-top:27px;

}

.circle-skill-block-title {
  width:153px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}


.circle-skill-block-circle-container {
  padding-top: 17px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

}

.circle-skill-block-circle {
  height:100px;
  width:100px;
  border-radius: 50px;
  position: relative;

}

.circle-skill-block-circle-text-container {
  height:100%;
  width:100%;
  position:absolute;
  margin-top:33px;

}

.circle-skill-block-circle-number-text-container {
  width:100%;
  font-size: 28px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;

}

.circle-skill-block-circle-percentile-text-container {
  width:100%;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align:center;
  line-height: 14px;


}

.circle-skill-block-progressbar-container {
  height:100%;
  width:100%;
  position:absolute;

}

.circle-skill-block-see-details-container {
  padding-top: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 18px;
  border-top: 1px solid #EBEDF2;

}

.circle-skill-block-see-details {
  font-size: 16px;
  color: #358EFE;
  opacity:1;

}

.circle-skill-block-see-details:hover {
  opacity:0.6;

}

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #348EFF;

  -webkit-transition: stroke-dashoffset 0.5s ease 0s;

  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #EBEDF2;
}

.profile-projects-section {
  width:100%;
  background-color: #FFFFFF;
  padding-bottom:15px;
}

.profile-projects-new-project-button-container {
  position:absolute;
  display:flex;
  justify-content: flex-end;
  width:100%;
  pointer-events:none;
}


.profile-projects-new-project-button {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-right:4.5vw;
  margin-top:38px;
  opacity:1;
  pointer-events:auto;
}

.profile-projects-new-project-button-loading {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-right:4.5vw;
  margin-top:38px;
  opacity:0.6;
}

@media (max-width: 900px) {
  .profile-projects-new-project-button-container {
    position:static;
    display:flex;
    justify-content: center;
    width:100%;
  }

  .profile-projects-new-project-button {
    background: #FFFFFF;
    border: 1px solid #EBEDF2;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
    border-radius: 8px;
    height:53px;
    width:173px;
    margin-right:0;
    margin-top:32px;
    opacity:1;
  }

  .profile-projects-new-project-button:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


  }

}

.profile-projects-new-project-button:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


}

.profile-projects-new-project-button-label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;

}

.profile-projects-new-project-button-icon {
  margin-right:7px;
  display: flex;
  align-items: center;
  justify-content: center;


}

.profile-projects-new-project-button-label {
  color:#838383;

}

.profile-projects-section-container {
  display:flex;
  padding-top:49px;
  padding-bottom:65px;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-projects-section-container-main {
  width:100%;


}

@media (max-width: 600px) {

  .profile-projects-section-container {
    display:flex;
    padding-top:32px;
    padding-bottom:65px;
    align-items: center;
    justify-content: center;

  }


}

.profile-projects-section-title-container {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-projects-section-title {
  font-size: 35px;
  font-weight: 500;
}

.profile-projects-section-project-blocks-container {
  padding-right: 15px;
  padding-left: 15px;


}

.project-block-container {
  margin-top:54px;
  display:flex;
  width:100%;
  justify-content: center;

}


.rofile-projects-section-empty-state {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-projects-section-empty-state-message {
  font-size:16px;
  color:#767676;
  margin-top:6px;
  text-align:center;
}

.profile-projects-new-project-button-container-empty {
  display:flex;
  justify-content: center;
  width:100%;
}


.profile-projects-new-project-button-empty {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-top:28px;
  opacity:1;
}

.profile-projects-new-project-button-empty-loading {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-top:28px;
  opacity:0.6;
}

.profile-projects-new-project-button-empty:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


}

.project-block {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
  overflow: hidden;
  font-size:18px;
  flex-basis: 900px;

}

.project-block-header {
  height:159px;
  overflow: hidden;
  width:100%;
  position: relative;

}

@media (max-width: 600px) {
  .project-block-header {
    height:140px;
  }

}

.project-block-status-badge-container {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  display:flex;
  justify-content:flex-end;

}

.project-block-status-badge {
  opacity: 0.7;
  background: #FFFFFF;
  border-radius: 8px;
  padding-left: 6px;
  padding-right:6px;
  padding-top:1px;
  padding-bottom:1px;
  margin-top:19px;
  margin-right:23px;
  font-size:14px;

}

@media (max-width: 600px) {
  .project-block-status-badge-container  {


  }

  .project-block-status-badge {
    margin-top:10px;
    margin-right:10px;


  }

}
.project-block-header-background-image {
  width:100%;
  display:flex;
  object-fit:cover;
  margin-top:auto auto auto auto;
  opacity:1;
  max-height:159px;

}

.project-block-header-container {
  background-color:rgba(0,0,0,0.73);
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:flex;

}

.project-block-title {
  width:394px;
  text-align:center;
  color:#FFFFFF;
  font-size:30px;
  font-weight: 500;

}

.project-block-title-placeholder {
  width:394px;
  text-align:center;
  color:#FFFFFF;
  font-size:30px;
  font-weight: 500;
  opacity:0.7;

}

@media (max-width: 600px) {
  .project-block-title {
    font-size:22px;
    padding:0 5px 0 5px;

  }

  .project-block-title-placeholder {
    font-size:22px;
    padding:0 5px 0 5px;

  }
}

.project-block-meta-data-section {
  //border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 1px #EBEDF2;
  display:flex;
  align-items: center;
  justify-content: center;
}

.meta-data-block-1 {
  padding-top:16px;
  padding-bottom:16px;
  padding-left: 10px;
  padding-right: 10px;
  width:50%;
  height:100%;
  text-align:center;
  border-right: 1px solid #EBEDF2;
  font-style: italic;

}

.meta-data-block-2 {
  padding-top:16px;
  padding-bottom:16px;
  padding-left: 10px;
  padding-right: 10px;
  width:50%;
  height:100%;
  text-align:center;
  box-shadow: -1px 0 0 #EBEDF2;
  font-style: italic;


}

.project-block-description-section {
  display:flex;
  align-items: center;
  justify-content: center;
}

.project-block-description {
  padding-top:32px;
  padding-bottom:15px;
  text-align:center;
  max-width:773px;
  padding-right:10px;
  padding-left:10px;

}

.project-block-skill-container {
  align-items: center;
  justify-content: center;
  padding-bottom:15px;
  width:100%;

}

.project-block-skill-blocks-container {
  display:flex;
  align-items: center;
  justify-content: center;
  padding-bottom:12px;
  width:100%;
  flex-flow: row wrap;


}

.project-block-more-skills {
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  color:#767676;
  padding-top:5px;

}

.project-block-skill-block-container {
  display:inline-block;

}


.project-block-see-all {
  padding-top:18px;
  padding-bottom:18px;
  text-align:center;
  color:#358EFE;
  opacity:1;
  font-size:16px;
  border-top: 1px solid #EBEDF2;

}

.project-block-see-all:hover {
  opacity:0.6;


}

.edit-profile-modal-overlay{
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.7);
  z-index: 3000;
  width:100%;
  top:0;
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.edit-profile-modal-container {
  margin-top:5VH;
  margin-left: 10px;
  margin-right:10px;
}

.edit-profile-modal {
  margin:0 auto 0 auto;
  font-size:18px;
  background: #FFFFFF;
  border-radius: 8px;
  display:flex;
  flex-basis: 600px;
  max-width:600px;

}

.edit-profile-modal-responsive-container{
  width:100%;


}

.edit-profile-modal-top-bar {
  height:33px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.edit-profile-modal-x-container {
  padding-right:20px;
  padding-top:15px;

}

.edit-profile-modal-x {
  opacity:0.5;

}

.edit-profile-modal-x:hover {
  opacity:1;

}

.edit-profile-modal-title-body {

  width:100%;
  border-bottom: 1px solid #EBEDF2;
  padding-bottom:13px;
}

.edit-profile-modal-title-container {
  display:flex;

}

.edit-profile-modal-title {
  width:390px;
  margin: 0 auto 0 auto;

}

.edit-profile-modal-title-label {
  font-size: 30px;
  font-weight: 500;
  text-align:center;
}

.edit-profile-modal-scroll-container {
  overflow-y:auto;
  overflow-x:hidden;
  height:60VH;


}

.edit-profile-modal-section-1 {
  padding-top: 26px;
  padding-bottom: 26px;
  width:100%;
  border-bottom: 1px solid #EBEDF2;
}

.edit-profile-modal-section-1-container{
  align-items: center;
  height:100%;
  padding-left:50px;
  padding-right:50px;
  flex-basis: 600px;
  max-width:600px;
  display:flex;


}


@media only screen and (max-width: 600px) {
  .edit-profile-modal-section-1-container{
    display:block;
  }
}

.edit-profile-modal-username-field-container{
  display:flex;
  flex-basis: 362px;
  max-width:362px;


}




.edit-profile-modal-username-field-label {
  font-style: italic;
  margin-bottom: 3px;

}

.edit-profile-modal-username-field-description {
  font-size: 14px;
  margin-top: 3px;
  color: #838383;
  max-width:362px;

}

.username-in-description {
  color:#242424;


}





.edit-profile-modal-username-field {
  height:65px;
  width:362px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}

.edit-profile-modal-profile-photo {
  display:flex;
  justify-content: flex-end;
  width:100%;

}

@media (max-width: 600px) {
  .edit-profile-modal-profile-photo {
    display:block;
    margin-top:15px;

  }

}

.edit-profile-modal-profile-picture {
  background: #D8D8D8;
  height: 111px;
  width:111px;
  border-radius: 55.5px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.edit-profile-modal-profile-photo-exists{
  height: 100%;
  width:100%;
  position:relative;
  opacity:1;

}

.edit-profile-modal-profile-photo-exists:hover{
  opacity:0.6;

}

.edit-profile-modal-profile-picture img {
  height:100%;
  position:absolute;
  width:100%;
  object-fit: cover;

}

.edit-profile-modal-profile-photo-overlay{
  position:absolute;
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.63);
  display:flex;
  justify-content: center;
  align-items: center;

}



.edit-profile-modal-profile-photo-overlay-message{
  color:#FFFFFF;
  text-align: center;
  font-size:12px;
  line-height: 15px;
  width:61px;

}

.edit-profile-modal-profile-photo-doesnt-exists {
  height: 100%;
  width:100%;
  position:relative;
  opacity:1;


}

.edit-profile-modal-profile-photo-doesnt-exists:hover {
  opacity:0.6;


}

.edit-profile-modal-profile-picture-letter-container {
  position:absolute;
  height: 100%;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.edit-profile-modal-profile-picture-letter {
  font-size:40px;
  color:#959595;
  position:absolute;

}

.edit-profile-modal-profile-photo-upload{
  position:absolute;
  z-index:1000;
  height:100%;
  width:100%;
  padding-right:200px;
  margin-left:-200px;

}



.edit-profile-modal-section-2 {
  width:100%;
  border-bottom: 1px solid #EBEDF2;
}

.edit-profile-modal-section-2-container {
  height:100%;
  padding-left:50px;
  padding-right:50px;
  padding-bottom:36px;


}
.edit-profile-modal-section-2-row-1{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:36px;

}

.edit-profile-modal-section-2-column-1{
  width:100%;
  margin-right:17px;

}

.edit-profile-modal-section-2-column-2{
  width:100%;

}


@media (max-width: 600px) {
  .edit-profile-modal-section-2-row-1{
    display:block;
    align-items: center;
    justify-content: space-between;
    width:100%;


  }

  .edit-profile-modal-section-2-column-2 {
    padding-top:36px;


  }

}

.edit-profile-modal-first-name-field-container {
  display:flex;
  width:100%;

}

.edit-profile-modal-first-name-field-label {
  font-style: italic;
  margin-bottom: 3px;

}


.edit-profile-modal-first-name-field {
  height:65px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}


.edit-profile-modal-last-name-field-container {
  display:flex;
  width:100%;

}

.edit-profile-modal-last-name-field-label {
  font-style: italic;
  margin-bottom: 3px;
  margin-top:1

}


.edit-profile-modal-last-name-field {
  height:65px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}

.edit-profile-modal-bio-field-container {
  display:flex;

}

.edit-profile-modal-bio-field-label {
  font-style: italic;
  margin-bottom: 3px;

}


.edit-profile-modal-bio-field {
  height:65px;
  width:436px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}



.edit-profile-modal-section-3 {
  width:100%;
  border-bottom: 1px solid #EBEDF2;
}


.edit-profile-modal-section-3-container {
  padding-left:50px;
  padding-right:50px;
  width:100%;


}

.edit-profile-modal-section-3-row {
  width:100%;
  padding-bottom:36px;


}
.edit-profile-modal-section-3{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:36px;

}

.edit-profile-modal-call-to-action-field-container {
  display:flex;
  flex-basis:1000px

}

.edit-profile-modal-call-to-action-field-label {
  font-style: italic;
  margin-bottom: 3px;

}


.edit-profile-modal-call-to-action-field {
  height:65px;
  width:100%;
  flex-basis: 436px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}

.edit-profile-modal-call-to-action-field-description {
  font-size: 14px;
  margin-top: 3px;
  color: #838383;

}


.edit-profile-modal-button-section {
  display:flex;
  border-top: 1px solid #EBEDF2;

}

.edit-profile-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: space-between;
  font-size: 18px;
}

.edit-profile-modal-close-button {
  height:52px;
  width:169px;
  text-align: center;
  display:inline-block;
  background-color: none;
  color:#5C5C5C;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #DCDCDC inset;
  opacity:1;
}

.edit-profile-modal-close-button:hover {
  opacity:0.6;

}

.edit-profile-modal-close-button-label {
  margin-top:13px;

}

.edit-profile-modal-save-button-disabled {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}


.edit-profile-modal-save-button {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
}

.edit-profile-modal-save-button:hover {
  opacity:0.6;


}

.edit-profile-modal-save-button-label {
  margin-top:13px;

}

.top-bar-branding {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-top: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  background:#FFF;
  height:20px;
  position:fixed;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  opacity:1;
  visibility: visible;
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  bottom:0;
  z-index: 100000;

}

.top-bar-branding-cta{
  font-size: 16px;
  color: #348EFF;
}

.top-bar-branding-cta:hover{
  opacity:0.6;
}

.top-bar-branding-pushdown{
  width:100%;
  top:0;
  height:40px;
}


textarea::-webkit-input-placeholder {
color: #838383;
}

textarea:-moz-placeholder { /* Firefox 18- */
color: #838383;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
color: #838383;
}

textarea:-ms-input-placeholder {
color: #838383;
}

.header-bar {
  width:100%;
  height:108px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  position:fixed;
  z-index: 100;
}

.header-bar-container {
  display:flex;

}

.header-bar-person {
  width:50%;
  height:108px;
  display: inline-block;
  text-align:center;
  border-right: 1px solid #EBEDF2;
}

.header-bar-person-container {
  display:flex;
  align-items: center;
  justify-content: center;
  height:108px;

}

.header-bar-person-label {
  font-size: 25px;
  font-weight: 500;

}

.underline {
  text-decoration: underline;

}

.header-bar-person-skill-label {
  font-size:16px;
  margin-top:3px;


}

.blue {
  color:#348EFF;

}

.projects-preview-section {
  padding-top: 108px;

}

.projects-preview-section-container {
  display:flex;
  position:fixed;


}

.project-section-container {
  min-height:100VH;
  width:50%;
  display:inline-block;
  border-right: 1px solid #EBEDF2;
  height: 100vh;
  overflow: auto;

}

.project-container {
    justify-content: center;
    width:50vw;
    padding-bottom:200px;

}

.project-section {
  padding-top:37px;
  width:100%;

}

.project-section-label {
  font-size: 18px;
  font-weight: 500;
  text-align: center;

}

.project-section-body {
  display:flex;
  font-size: 18px;
  line-height: 30px;

  justify-content: center;
  width:100%;
}

.project-section-body-container {
  margin-top:19px;
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  width:75%;
  padding-top:23px;
  padding-bottom:23px;
  display:flex;
  justify-content: center;
}

.project-section-body-text-container {
  width:100%;
}

.project-section-body-text {
  font-size:!important 18px;

}

.project-section-body-textarea {
  font-size: 18px;
  color: #242424;
  font-family: Brandon;
  letter-spacing: 0;
  width:100%;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  box-shadow: none;
}

.review-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,1);
  z-index: 101;
  width:100%;
  top:0;
  opacity:0.1;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

}


.review-menu{
  overflow: hidden;
  width:100%;
  bottom:0;
}


.review-menu-container {
  width:100%;
  height:461px;
  background-color: #FFFFFF;
  border-top: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  bottom:0;
  position:fixed;
  z-index: 102;
    -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.review-menu-top-tab {
  height:48px;
  width:100%;
  border-bottom: 1px solid #EBEDF2;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align:center;
}

.review-menu-top-tab-label-container {
  opacity:1;

}

.review-menu-top-tab-label-container:hover {
  opacity:0.6;

}

.review-menu-top-tab-arrow {
  display:flex;
  justify-content: center;
  margin-bottom:1px;

}

.review-menu-top-tab-label {
  color: #767676;
  font-size:14px;

}

.review-menu-body-container {
  margin-top:27px;

}

.review-menu-back-to-dashboard {
  display:absolute;
  margin-left:45px;
  margin-bottom:-20px;
  color:#767676;
  font-size:14px;
  opacity:1;

}

.review-menu-back-to-dashboard:hover {
  opacity:0.6;

}

.review-menu-back-to-dashboard-label{
  margin-left:5px;
  display:inline-block;

}

.review-menu-submit-review-block-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.review-menu-submit-review-block {
  height:299px;
  width:553px;
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
}

.review-menu-submit-review-block-label-container {
  height:61px;
  border-bottom: 1px solid #EBEDF2;
  display:flex;
  width:100%;
  align-items: center;
  justify-content: center;
}

.review-menu-submit-review-block-label {
  font-size: 20px;
  font-weight: 500;
}

.review-menu-submit-review-block-body{
  padding-top:16px;
  padding-bottom:30px;
  border-bottom: 1px solid #EBEDF2;
  display:flex;
  width:100%;
  align-items: center;
  justify-content: center;

}

.review-menu-submit-review-block-prompt {
  font-size: 18px;
  text-align:center;
  margin-left:10px;
  margin-right:10px;
}

.review-menu-submit-review-block-prompt-label {
  margin-left:10px;
  margin-right:10px;

}

.underline-bold {
  text-decoration: underline;
  font-weight: 500;

}

.review-menu-submit-review-block-persons {
  margin-top:24px;

}

.review-menu-submit-review-block-persons-container {
  width:173px;
  display:flex;
  justify-content: center;
  width:100%;

}

.person-block-a {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:55px;
  width:173px;
  display:inline-block;
  margin-right:30px;
  text-align:center;
  vertical-align: center;

}

.person-block-b {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:55px;
  width:173px;
  display:inline-block;
  text-align:center;

}

.person-block-label {
  display:flex;
  justify-content: center;
  align-items: center;
  height:100%;
  opacity:0.3;
  font-size:16px;
  font-weight: 500;

}

.submit-button {
  height:57px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:18px;
  color: #2D8BFF;
  opacity:1;
}

.submit-button:hover {
  opacity:0.6;

}

.submit-button-label {
  opacity:1;

}

.submit-button-label:hover {
  opacity:0.6;

}



.review-menu-progress-bar {
  display:flex;
  margin-top:19px;
  flex-direction: column;
}

.review-menu-progress-bar-label {
  display:inline-block;
  font-size:14px;
  margin-right: 10px;
  margin-bottom: 8px;
}

.review-menu-progress-bar-container {
  display:flex;
  align-items: center;
  justify-content: center;
}

.review-menu-progress-bar-background {
  height: 9px;
  width:192px;
  background: #EBEDF2;
  border-radius: 4.5px;
  overflow:hidden;
  display:inline-block;
  margin-right: 9px;
  margin-bottom: 40px;

}

.review-menu-progress-bar-percentile {
  background: #348EFF;
  border-radius: 4.5px 0 0 4.5px;
  height:100%;

}

.searching-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(255,255,255,1);
  z-index: 103;
  width:100%;
  top:0;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.searching-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  height:100VH;

}

.searching-container-vertical-center {
    padding-bottom:100px;

}

.searching-message-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
}

.searching-message {
  font-size:30px;
  font-weight: 500;

}

.screen-size-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,1);
  z-index: 103;
  width:100%;
  top:0;
  -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  visibility:hidden;
  opacity:0;
}


@media only screen and (max-width: 900px) {
    .screen-size-overlay {
        visibility: visible;
        opacity:0.9;
    }
}

.screen-size-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  height:100VH;

}

.screen-size-container-vertical-center {
    padding-bottom:100px;

}

.screen-size-message-container{

  justify-content: center;
  text-align:center;
  width:100%;
  align-items: center;
  margin-bottom:15px;

}

.screen-size-message {
  font-size:22px;
  font-weight: 500;
  color:white;


}

.screen-size-message-small {
  font-size:16px;
  font-weight: 400;
  color:#AFAFAF;
  margin-top:10px;

}

.static-underline {
  text-decoration: underline;
  font-style: italic;
}

.homepage-scroll-top-bar {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  position:fixed;
  z-index:1;
  background:#FFF;
  height:40px;
  display:flex;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  opacity:1;
  visibility: visible;
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0);

}

.homepage-scroll-top-bar-hidden {
  opacity:0;
  visibility: hidden;
}

.homepage-scroll-bar-cta{
  font-size: 18px;
  color: #348EFF;
}

.homepage-scroll-bar-cta:hover{
  opacity:0.6;
}

.homepage-header-bar-container{
display:flex;
width: 1200px;
justify-content: space-between;
margin-left: 25px;
margin-right: 25px;
}


.homepage-header-bar {
  height:97px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;

}

.homepage-header-bar-logo {
  display:flex;
  align-items: center;
  font-size:22px;
  font-weight: 500;

}

.homepage-header-bar-logo-2 {
  display:flex;
  align-items: center;
  font-size:22px;
  font-weight: 500;
  margin-left: 9px;
  line-height:22px;
  margin-bottom:3px;

}

.homepage-header-bar-container {
  display:flex;
  width: 1200px;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;

}

.homepage-header-bar-links {
  display:flex;

}

.homepage-header-bar-links-link-container {
  margin-left:60px;
}

.homepage-header-bar-links-link-container-text {
  font-size: 16px;
  color: #838383;
}

.homepage-header-bar-links-link-container-text:hover {
  color: #348EFF;
}



.homepage-container {
  text-align:center;
  padding-bottom:200px;
  margin-left:25px;
  margin-right:25px;
}

.homepage-hero-section {
  width:100%;
  margin-top:17px;

}

.homepage-hero-section-title {
  font-weight: 500;
  font-size: 100px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;


}

@media only screen and (max-width: 700px) {
  .homepage-hero-section-title {
    font-size: 50px;
  }
}

.homepage-hero-section-sub-title-container {
  display:flex;
  justify-content: center;

}


.homepage-hero-section-sub-title {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;
  margin-top:10px;
  display:flex;
  max-width:688px;

}

.homepage-hero-section-cta-button-container {
  display:flex;
  justify-content: center;

}

.homepage-hero-section-cta-button {
  width:256px;
  height:56px;
  background: #348EFF;
  border-radius: 7.2px;
  margin-top:46px;
  display:flex;
  align-items: center;
  justify-content: center;

}

.homepage-hero-section-cta-button:hover {
  background: #69ABFF;

}

.homepage-hero-section-cta-button-label {
  font-weight:400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;

}

.homepage-hero-section-profile-image-container-center{
  margin-top: 82px;
  display: flex;
  justify-content: center;
  width:100%;

}

.homepage-hero-section-profile-image-container {
  display:flex;
  max-width: 900px;

}


.homepage-hero-section-profile-image {
  box-shadow: 0 0 34px 0 rgba(189,189,206,0.21);
  width:100%;
  height:100%;
}

.homepage-about-section {
  width:100%;
  text-align: center;

}

.homepage-about-section-section-header-container {
  margin-top:103px;

}


.homepage-section-header{
  font-weight:500;
  font-size: 50px;
  color: #242424;
  text-align: center;
}

.homepage-about-section-section-body-container {
  display:flex;
  justify-content: center;
  width:100%;
  margin-top:25px;
}


.homepage-section-body {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;

  max-width:732px;

}

.homepage-section-body-highlight {
  background-color:#D5E8FF;

}

.homepage-about-section-profile-image-container-center{
  margin-top: 25px;
  display: flex;
  justify-content: center;
  width:100%;

}

.homepage-about-section-profile-image-container {
  display:flex;
  max-width: 750px;

}


.homepage-about-section-profile-image {
  width:100%;
  height:100%;
}

.homepage-divider-container {
  width:100%;
  display:flex;
  justify-content: center;

}

.homepage-divider {
  width:169px;
  height:3px;
  background: #F0F2F5;
  margin-top:103px;
  margin-bottom:71px
}

.homepage-how-it-works-section-italics-label {
  font-style: italic;
  font-size: 35px;
  color: #838383;
  text-align: center;


}

.homepage-how-it-works-section-down-arrow {

  margin-top:37px;
}

.homepage-how-it-works-section-step-container {
  margin-top:33px;

}

.homepage-how-it-works-section-profile-image-container-center{
  margin-top: 56px;
  display: flex;
  justify-content: center;
  width:100%;

}

.homepage-how-it-works-section-down-arrow-2 {

  margin-top:77px;
}

.homepage-section-body-linked {
  text-decoration: underline;

}

.homepage-section-body-linked:hover {
  opacity:0.6;

}


.homepage-footer {
  height:57px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #EBEDF2;

}

.homepage-footer-container {
  display:flex;
  width: 1200px;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
  flex-wrap: wrap;

}

.homepage-footer-links {
  display:flex;

}

.homepage-footer-links-link-container {
  margin-left:60px;
}

.homepage-footer-links-link-container-text {
  font-size: 16px;
  color: #838383;
}

.homepage-footer-links-link-container-text-copyright {
  font-size: 16px;
  color: #838383;
}

.homepage-footer-links-link-container-text:hover {
  color: #348EFF;
}

@media (max-width: 800px) {
  .homepage-footer-container {
    display:flex;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
    flex-wrap: wrap;

  }

  .homepage-footer {
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #EBEDF2;
    padding:10px 0 10px 0;

  }

  .homepage-footer-links {
    padding:10px 0 10px 0;

  }

  .homepage-footer-links-link-container {
    margin-left:10px;
    margin-right:10px;
  }

}

.create-account-modal-overlay{
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0);
  z-index: 5000;
  width:100%;
  top:0;
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.create-account-modal-container {
  margin-top:5VH;
  margin-left: 10px;
  margin-right:10px;
}

.create-account-modal {
  margin:0 auto 0 auto;
  font-size:18px;
  background: #FFFFFF;
  border-radius: 8px;
  display:flex;
  flex-basis: 600px;
  max-width:600px;
}

.create-account-modal-responsive-container{
  width:100%;


}

.create-account-modal-top-bar {
  height:33px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.create-account-modal-x-container {
  padding-right:20px;
  padding-top:15px;

}

.create-account-modal-x {
  opacity:0.5;

}

.create-account-modal-x:hover {
  opacity:1;

}

.create-account-modal-title-body {

  width:100%;

}

.create-account-modal-title-container {
  display:flex;
  justify-content:space-between;
  margin-left:70px;
  margin-right:70px;
  padding-bottom:10px;
  padding-top:10px;

}

@media (max-width: 600px) {
  .create-account-modal-title {
    width:100%;
    text-align: centerl

  }

  .create-account-modal-title-container {
    display:block;


  }

}



.create-account-modal-title-label {
  font-size: 30px;
  font-weight: 500;
  text-align:center;
  width:100%;
}



.create-account-modal-scroll-container {
  overflow-y:auto;
  overflow-x:hidden;
  max-height: 324px;
}


.create-account-modal-section {
  width:100%;
}

.create-account-modal-section-container {
  height:100%;
  padding-left:70px;
  padding-right:70px;
  padding-bottom:16px;


}

@media (max-width: 600px) {
  .create-account-modal-section-container {

    padding-left:30px;
    padding-right:30px;
    padding-bottom:16px;
    padding-top:30px;


  }
}
.create-account-modal-section-row{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:16px;

}

.create-account-modal-section-column-padding-right {
  width:100%;
  margin-right: 17px;

}

.create-account-modal-section-column{
  width:100%;


}


@media (max-width: 600px) {
  .create-account-modal-section-row{
    display:block;
    align-items: center;
    justify-content: space-between;
    width:100%;


  }

  .create-account-modal-section-column {
    padding-top:16px;


  }

  .create-account-modal-section-row{
    padding-top:0px;

  }

}

.create-account-modal-half-field-container {
  display:flex;
  width:100%;

}

.create-account-modal-field-container {
  display:flex;
  width:100%;

}

.create-account-modal-half-field {
  height:65px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}



.create-account-modal-field {
  height:65px;
  width:436px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}


.create-account-accept-message {
  font-size:12px;
  text-align: center;
  color:#ABABAB;
  margin-top:3px;

}

.create-account-modal-error-message {
  font-size:13px;
  text-align: center;
  color:#EF7879;
  margin-top:3px;
    -webkit-transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

}

.create-account-modal-login-option {
  text-align: center;
  font-size: 13px;
  color:#ABABAB;
  display:flex;
  justify-content: center;
  margin-top:10px;
  align-items: center;

}

.blue-log-in {
  color:#348EFF;
  opacity:1;

}

.blue-log-in:hover {
  opacity:0.6;
}

.create-account-modal-button-section {
  display:flex;
  border-top: 1px solid #EBEDF2;

}

.create-account-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: center;
  font-size: 18px;
}


.create-account-modal-primary-button {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:210px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
}

.create-account-modal-primary-button-disabled {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:210px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}

.create-account-modal-primary-button-waiting {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:210px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}

.create-account-modal-primary-button:hover {
  opacity:0.6;
}

.create-account-modal-primary-button-label {
  margin-top:13px;

}

.skill-page-top-bar {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  position:fixed;
  z-index:1;
  background:#FFF;
}

.skill-page-top-bar-container{
  margin-left:25px;
  margin-right:25px;
}


.skill-page-top-section{
  margin-left:25px;
  margin-right:25px;
  padding-top:128px;
}

@media (max-width:600px) {
  .skill-page-top-section{
    padding-top:112px;
  }

}


.skill-page-back-to-profile {
  margin-left:4vw;
  color:#767676;
  font-size:14px;
  opacity:1;
  position:absolute;
  margin-top:10px;
}

.skill-page-back-to-profile:hover {
  opacity:0.6;

}


.skill-page-back-to-profile-label{
  margin-left:5px;
  display:inline-block;

}

.skill-page-back-to-profile-label-mobile{
  margin-left:5px;
  display:none;
}

@media (max-width: 600px) {
  .skill-page-back-to-profile-label{
    margin-left:5px;
    display:none;

  }

  .skill-page-back-to-profile-label-mobile{
    display:inline-block;

  }

  .skill-page-top-bar-container{
    margin-left:10px;
    margin-right:10px;
  }
}




.skill-page-profile-person-photo {
  height:40px;
  width:40px;
  min-width:40px;
  background-color:rgba(216,216,216,1);
  border-radius:20px;
  align-self:center;
  overflow:hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.skill-page-profile-person-photo img {
  height:100%;


}

.skill-page-profile-picture-letter-small {
  font-size:20px;
  color:#959595;

}

.skill-page-profile-person-name {
  display:inline-block;
  color:#242424;
  font-size:18px;
  font-weight:normal;
  align-self:center;
  margin-left:11px;

}

.skill-page-profile-person-container {
  display:flex;
}

.skill-page-profile-person-container:hover {
  display:flex;
  opacity:0.6;
}


.skill-page-profile-person {
  display:flex;
  justify-content: center;
  width:100%;
  opacity:1;

}



.skill-page-skill-name-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-bottom:10px;
}

.skill-page-skill-name {
  font-weight: 500;
  font-size: 50px;
  color: #242424;
  text-align: center;
}

@media (max-width: 600px) {
  .skill-page-skill-name {
    font-size: 35px;
  }

  .skill-page-profile-person-name {
    display:inline-block;
    color:#242424;
    font-size:18px;
    font-weight:normal;
    align-self:center;
    margin-left:11px;

  }

}

.skill-page-score-block-container-margin {

}


.skill-page-score-block-container{
  width:100%;
  display:flex;
  justify-content: center;
  margin-top:28px;
}

.skill-page-score-block {
  width:900px;
  display:flex;
  flex-basis:900px;
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  min-height:180px;
  border-radius:8px;


}

.skill-page-score-block-inside-container-left {
  height:100%;
  width:200px;
  border-right: 1px solid #EBEDF2;

}

.skill-page-score-block-score-container {
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.scale-up-20-percent {
  -webkit-transform: scale(1.2);
          transform: scale(1.2)
}

.skill-page-score-block-inside-container-right{
  height:100%;
  width:100%;

}

.skill-page-score-block-inside-container-right-center-container {
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.skill-page-score-block-inside-container-right-text-container{
  width:100%;
  padding:28px 42px 28px 42px;

}

.skill-page-score-block-heading {
  font-weight:500;
  font-size: 22px;
  color: #242424;
  margin-bottom:10px;
}

.skill-page-score-block-body {
  font-size: 20px;
  color: #242424;
}


.skill-page-show-when-small {
  display:none;

}


@media (max-width:600px) {
  .skill-page-show-when-small {
    display:block;

  }

  .skill-page-score-block-container-margin{
    display:none;

  }

}






.skill-page-description-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-top:18px;

}

.skill-page-description {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;
  max-width:900px;
}

.skill-page-description-blue-span {
  font-weight:500;
  color: #348EFF;
}

.skill-page-divider-container{
  display:flex;
  justify-content: center;
  width: 100%;
}

.skill-page-divider {
  height:1px;
  width:100%;
  background-color: #EBEDF2;
  margin-top:60px;
  margin-bottom:60px;

}

.skill-page-case-study-section {
  margin-bottom:100px;
  margin-top:20px;
  margin-left:15px;
  margin-right:15px;

}


.skill-page-case-study-label-container {
  display:flex;
  justify-content: center;
  width: 100%;

}

.skill-page-case-study-label {
  font-weight: 500;
  font-size: 35px;
  color: #242424;
  text-align: center;
}

.review-lander-top-bar {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  position:fixed;
  z-index:1;
  background:#FFF;
}

.review-lander-top-bar-container{
  margin-left:25px;
  margin-right:25px;
}


.review-lander-top-section{
  margin-left:25px;
  margin-right:25px;
  padding-top:133px;
}

@media (max-width:600px) {
  .review-lander-top-section{
    padding-top:112px;
  }

}


.review-lander-back-to-profile {
  margin-left:4vw;
  color:#767676;
  font-size:14px;
  opacity:1;
  position:absolute;
  margin-top:10px;
}

.review-lander-back-to-profile:hover {
  opacity:0.6;

}


.review-lander-back-to-profile-label{
  margin-left:5px;
  display:inline-block;

}

.review-lander-back-to-profile-label-mobile{
  margin-left:5px;
  display:none;
}

@media (max-width: 600px) {
  .review-lander-back-to-profile-label{
    margin-left:5px;
    display:none;

  }

  .review-lander-back-to-profile-label-mobile{
    display:inline-block;

  }

  .review-lander-top-bar-container{
    margin-left:10px;
    margin-right:10px;
  }
}




.review-lander-profile-person-photo {
  height:40px;
  width:40px;
  min-width:40px;
  background-color:rgba(216,216,216,1);
  border-radius:20px;
  align-self:center;
  overflow:hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.review-lander-profile-person-photo img {
  height:100%;


}

.review-lander-profile-picture-letter-small {
  font-size:20px;
  color:#959595;

}

.review-lander-profile-person-name {
  display:inline-block;
  color:#242424;
  font-size:18px;
  font-weight:normal;
  align-self:center;
  margin-left:11px;

}

.review-lander-profile-person-container {
  display:flex;
}

.review-lander-profile-person-container:hover {
  display:flex;
  opacity:0.6;
}


.review-lander-profile-person {
  display:flex;
  justify-content: center;
  width:100%;
  opacity:1;

}



.review-lander-skill-name-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-bottom:10px;
}

.review-lander-skill-name {
  font-weight: 500;
  font-size: 50px;
  color: #242424;
  text-align: center;
}

@media (max-width: 600px) {
  .review-lander-skill-name {
    font-size: 35px;
  }

  .review-lander-profile-person-name {
    display:inline-block;
    color:#242424;
    font-size:18px;
    font-weight:normal;
    align-self:center;
    margin-left:11px;

  }

}

.review-lander-description-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-top:18px;

}

.review-lander-description {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;
  max-width:900px;
}

.review-lander-description-blue-span {
  font-weight:500;
  color: #348EFF;
}

.review-lander-divider-container{
  display:flex;
  justify-content: center;
  width: 100%;
}

.review-lander-divider {
  height:1px;
  width:100%;
  background-color: #EBEDF2;
  margin-top:90px;
  margin-bottom:60px;

}


.review-lander-cta-button-container {
  display:flex;
  justify-content: center;

}

.review-lander-cta-button {
  width:256px;
  height:56px;
  background: #348EFF;
  border-radius: 7.2px;
  margin-top:46px;
  display:flex;
  align-items: center;
  justify-content: center;

}

.review-lander-cta-button:hover {
  background: #69ABFF;

}

.review-lander-cta-button-label {
  font-weight:400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;

}

.review-lander-faq-section{
  background: #FAFBFE;
  border: 1px solid #EBEDF2;
  padding-top:83px;
  padding-bottom:183px;
  margin-top:100px;

}

.review-lander-faq-section-container-margin {
  padding-right:15px;
  padding-left:15px;
}

.review-lander-faq-section-container {
  width:100%;
}

.review-lander-faq-section-title{
  font-weight: 500;
  font-size: 35px;
  color: #242424;
  text-align: center;
  width:100%;
  margin-bottom:29px;

}

.review-lander-faq-section-cards {
  display:flex;
  width:100%;
  justify-content: center;
}

.faq-card {
  display:flex;
  max-width:900px;
  flex-basis:900px;
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  padding:40px 56px 40px 56px;
  margin-top:36px;
  border-radius: 8px;
}

.faq-card-inner-container {
  width:100%;
  height:100%;

}

.faq-card-question-title {
  font-weight: 500;
  font-size: 22px;
  color: #242424;
  margin-bottom: 16px;
}

.faq-card-question-answer {
  font-size: 20px;
}

@font-face {
font-family: 'Brandon';
src: url(/static/media/Brandon_reg.1f55925e.otf) format('truetype');
font-weight: normal;
font-style: normal;
}
@font-face {
font-family: 'Brandon';
src: url(/static/media/Brandon_reg_it.d1b49508.otf) format('truetype');
font-weight: normal;
font-style: italic;
 }

 @font-face {
 font-family: 'Brandon';
 src: url(/static/media/Brandon_med_it.35ddef06.otf) format('truetype');
 font-weight: bold;
 font-style: italic;
  }

@font-face {
font-family: 'Brandon';
src: url(/static/media/Brandon_med.a989c5b7.otf) format('truetype');
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'Brandon';
src: url(/static/media/Brandon_med.a989c5b7.otf) format('truetype');
font-weight: bold;
font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Brandon','Josefin Sans','Lato',san-serif;
  cursor: default;

}

::-webkit-input-placeholder {
  color: #838383;
}

::-ms-input-placeholder {
  color: #838383;
}

::placeholder {
  color: #838383;
}

input {
  box-shadow: none;
}

