.profile-skills-section {
  width:100%;
  background-color: #FAFBFE;
  border-bottom: 1px solid #EBEDF2;

}

.profile-skills-section-container {
  display:flex;
  padding-top:49px;
  padding-bottom:65px;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right:15px;
}

.profile-skills-section-title-container {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-skills-section-title {
  font-size: 35px;
  font-weight: 500;
}


.profile-skills-section-circle-skill-blocks-container {
  display:flex;
  justify-content: center;
  width:100%;
  margin-top:34px;
  margin-bottom:24px;
  flex-flow: row wrap;
  max-width:1200px;


}

.profile-skills-section-circle-skill-block-container {
  margin-left:15px;
  margin-right:15px;
  margin-bottom:30px

}


.profile-skills-section-quantified-message {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-skills-section-quantified-message-container {
  font-size:16px;
  font-style:italic;
  margin-top:6px;
  text-align:center;
}

.project-skills-underline {
  text-decoration: underline;
  opacity:1;

}

.project-skills-underline:hover {
  opacity:0.6;

}

.profile-skills-section-show-all {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
  margin-top:40px;

}

.profile-skills-section-show-all-container {
  opacity:1;
}

.profile-skills-section-show-all-container:hover {
  opacity:0.6;


}

.profile-skills-section-show-all-text {
  font-size:16px;
  margin-top:6px;
  text-align:center;
  color: #767676;

}

.profile-skills-section-show-all-arrow {
  width:100%;
  display: flex;
  justify-content: center;
  margin-top:9px;

}

.profile-skills-section-show-less-arrow {
  width:100%;
  display: flex;
  justify-content: center;
  margin-bottom:3px;

}




.profile-skills-section-empty-message {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-skills-section-empty-message-container {
  font-size:16px;
  color:#767676;
  margin-top:6px;
  text-align:center;
}
