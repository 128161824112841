.header-bar {
  width:100%;
  height:108px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  position:fixed;
  z-index: 100;
}

.header-bar-container {
  display:flex;

}

.header-bar-person {
  width:50%;
  height:108px;
  display: inline-block;
  text-align:center;
  border-right: 1px solid #EBEDF2;
}

.header-bar-person-container {
  display:flex;
  align-items: center;
  justify-content: center;
  height:108px;

}

.header-bar-person-label {
  font-size: 25px;
  font-weight: 500;

}

.underline {
  text-decoration: underline;

}

.header-bar-person-skill-label {
  font-size:16px;
  margin-top:3px;


}

.blue {
  color:#348EFF;

}
