.circle-skill-block {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
  width:200px;


}

.circle-skill-block-title-container {
  width:100%;
  display:flex;
  height:42px;
  justify-content: center;
  margin-top:27px;

}

.circle-skill-block-title {
  width:153px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}


.circle-skill-block-circle-container {
  padding-top: 17px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

}

.circle-skill-block-circle {
  height:100px;
  width:100px;
  border-radius: 50px;
  position: relative;

}

.circle-skill-block-circle-text-container {
  height:100%;
  width:100%;
  position:absolute;
  margin-top:33px;

}

.circle-skill-block-circle-number-text-container {
  width:100%;
  font-size: 28px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;

}

.circle-skill-block-circle-percentile-text-container {
  width:100%;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align:center;
  line-height: 14px;


}

.circle-skill-block-progressbar-container {
  height:100%;
  width:100%;
  position:absolute;

}

.circle-skill-block-see-details-container {
  padding-top: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 18px;
  border-top: 1px solid #EBEDF2;

}

.circle-skill-block-see-details {
  font-size: 16px;
  color: #358EFE;
  opacity:1;

}

.circle-skill-block-see-details:hover {
  opacity:0.6;

}
