.command-area {
  cursor:default;
  position:fixed;
  z-index:1000;
  width:100%;
  overflow: hidden;
  pointer-events:none;
}

.command-bar {
  width:100%;
  height:36px;
  background-color:rgba(64,64,64,1.0);
  border-bottom:1px solid rgba(51,51,51,1.0);
  overflow:hidden;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  pointer-events:auto;
}

.command-bar-hidden {
  width:100%;
  height:36px;
  background-color:rgba(64,64,64,1.0);
  border-bottom:1px solid rgba(51,51,51,1.0);
  overflow:hidden;
  z-index:1000;
  margin-top: -31px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.command-bar-container {
  display:flex;
  justify-content: space-between;
}

.command-bar-left-container {
  display:inline-block;
}

.command-bar-right-container {
  display:flex;
}

.hide-command-bar-block {
  height:36px;
  width: 38px;
  border-right:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-command-bar-arrow-container {
  opacity:0.53;
}

.hide-command-bar-arrow-container:hover {
  opacity:1.0;
}

.hide-command-bar-arrow {
  align-self:center;
  display:inline-block;
  align-self:center;
  display:inline-block;
  margin: auto auto auto auto;

}

.general-block-container {

}

.dashboard-block {
  color:rgba(255,255,255,1);
  font-size:14px;
  height:36px;
  width: 120px;
  border-left:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content: center;

}

.dashboard-block-container {
  opacity:0.53;
  display: flex;
  align-items: center;
}

.dashboard-block-container:hover {
  opacity:1.0;
}

.dashboard-grid-icon {
  display:inline-block;

}

.dashboard-block-label {
  display:inline-block;
  margin-left:8px;
}

.preview-block {
  color:rgba(255,255,255,1);
  font-size:14px;
  height:36px;
  width: 120px;
  border-left:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content:center;

}

.preview-block-container {
  opacity:0.53;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-block-container:hover {
  opacity:1.0;
}

.preview-eye-icon {
  display:inline-block;

}

.preview-block-label {
  display:inline-block;
}



.saved-block {
  height:36px;
  width: 38px;
  border-left:1px solid rgba(51,51,51,1.0);
  display:flex;
  justify-content:center;
}

.saved-block-container {
  display: flex;
  align-items: center;

}

.saved-successful-badge {
}


.publish-block {
  color:rgba(255,255,255,1);
  font-size:14px;
  height:36px;
  border-left:1px solid rgba(51,51,51,1.0);
  display: flex;
  justify-content: center;

}

.publish-block-container {
  opacity:0.53;
  display: flex;
  align-items: center;
  padding-left:17px;
  padding-right:17px;
}

.publish-block-container:hover {
  opacity:1.0;
}

.publish-airplane-icon {
  display:inline-block;

}

.publish-edits-icon-container {
  display:inline-block;
  flex-direction: row;
  margin-top: 2px;
  margin-right: 5px;
  margin-left: -2px;
  opacity: 1.0 !important;
}

.publish-edits-icon {
  display:inline-block;
}

.publish-block-label {
  display:inline-block;
  margin-left:8px;
  margin-right:8px;
}

.edit-block-label {
  display:inline-block;
  margin-left:8px;
}

.publish-dropdown-arrow {
  display:inline-block;
}

.publish-block-container-open {
  opacity:1.0;
  display: flex;
  align-items: center;
  padding-left:17px;
  padding-right:17px;


}

.command-publish-menu {
  z-index:1001;
  display:flex;
  margin-top:-8px;

}

.command-publish-menu-container {
  display:inline-block;
  margin-left: auto;
  margin-right: 7px;

}

.command-bar-tab {
  background-color:rgba(64,64,64,1.0);
  border-bottom:1px solid rgba(51,51,51,1.0);
  border-left:1px solid rgba(51,51,51,1.0);
  border-right:1px solid rgba(51,51,51,1.0);
  width: 26px;
  height:24px;
  margin-left:9px;
  margin-top:6px;
  border-radius: 0 0 3px 3px;
  position:fixed;
  z-index:1;


}

.hide-command-bar-block-tab {
  width: 26px;
  height:24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
