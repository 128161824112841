.skill-page-top-bar {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  position:fixed;
  z-index:1;
  background:#FFF;
}

.skill-page-top-bar-container{
  margin-left:25px;
  margin-right:25px;
}


.skill-page-top-section{
  margin-left:25px;
  margin-right:25px;
  padding-top:128px;
}

@media (max-width:600px) {
  .skill-page-top-section{
    padding-top:112px;
  }

}


.skill-page-back-to-profile {
  margin-left:4vw;
  color:#767676;
  font-size:14px;
  opacity:1;
  position:absolute;
  margin-top:10px;
}

.skill-page-back-to-profile:hover {
  opacity:0.6;

}


.skill-page-back-to-profile-label{
  margin-left:5px;
  display:inline-block;

}

.skill-page-back-to-profile-label-mobile{
  margin-left:5px;
  display:none;
}

@media (max-width: 600px) {
  .skill-page-back-to-profile-label{
    margin-left:5px;
    display:none;

  }

  .skill-page-back-to-profile-label-mobile{
    display:inline-block;

  }

  .skill-page-top-bar-container{
    margin-left:10px;
    margin-right:10px;
  }
}




.skill-page-profile-person-photo {
  height:40px;
  width:40px;
  min-width:40px;
  background-color:rgba(216,216,216,1);
  border-radius:20px;
  align-self:center;
  overflow:hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.skill-page-profile-person-photo img {
  height:100%;


}

.skill-page-profile-picture-letter-small {
  font-size:20px;
  color:#959595;

}

.skill-page-profile-person-name {
  display:inline-block;
  color:#242424;
  font-size:18px;
  font-weight:normal;
  align-self:center;
  margin-left:11px;

}

.skill-page-profile-person-container {
  display:flex;
}

.skill-page-profile-person-container:hover {
  display:flex;
  opacity:0.6;
}


.skill-page-profile-person {
  display:flex;
  justify-content: center;
  width:100%;
  opacity:1;

}



.skill-page-skill-name-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-bottom:10px;
}

.skill-page-skill-name {
  font-weight: 500;
  font-size: 50px;
  color: #242424;
  text-align: center;
}

@media (max-width: 600px) {
  .skill-page-skill-name {
    font-size: 35px;
  }

  .skill-page-profile-person-name {
    display:inline-block;
    color:#242424;
    font-size:18px;
    font-weight:normal;
    align-self:center;
    margin-left:11px;

  }

}

.skill-page-score-block-container-margin {

}


.skill-page-score-block-container{
  width:100%;
  display:flex;
  justify-content: center;
  margin-top:28px;
}

.skill-page-score-block {
  width:900px;
  display:flex;
  flex-basis:900px;
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  min-height:180px;
  border-radius:8px;


}

.skill-page-score-block-inside-container-left {
  height:100%;
  width:200px;
  border-right: 1px solid #EBEDF2;

}

.skill-page-score-block-score-container {
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.scale-up-20-percent {
  transform: scale(1.2)
}

.skill-page-score-block-inside-container-right{
  height:100%;
  width:100%;

}

.skill-page-score-block-inside-container-right-center-container {
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.skill-page-score-block-inside-container-right-text-container{
  width:100%;
  padding:28px 42px 28px 42px;

}

.skill-page-score-block-heading {
  font-weight:500;
  font-size: 22px;
  color: #242424;
  margin-bottom:10px;
}

.skill-page-score-block-body {
  font-size: 20px;
  color: #242424;
}


.skill-page-show-when-small {
  display:none;

}


@media (max-width:600px) {
  .skill-page-show-when-small {
    display:block;

  }

  .skill-page-score-block-container-margin{
    display:none;

  }

}






.skill-page-description-container {
  display:flex;
  justify-content: center;
  width: 100%;
  margin-top:18px;

}

.skill-page-description {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;
  max-width:900px;
}

.skill-page-description-blue-span {
  font-weight:500;
  color: #348EFF;
}

.skill-page-divider-container{
  display:flex;
  justify-content: center;
  width: 100%;
}

.skill-page-divider {
  height:1px;
  width:100%;
  background-color: #EBEDF2;
  margin-top:60px;
  margin-bottom:60px;

}

.skill-page-case-study-section {
  margin-bottom:100px;
  margin-top:20px;
  margin-left:15px;
  margin-right:15px;

}


.skill-page-case-study-label-container {
  display:flex;
  justify-content: center;
  width: 100%;

}

.skill-page-case-study-label {
  font-weight: 500;
  font-size: 35px;
  color: #242424;
  text-align: center;
}
