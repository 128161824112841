
textarea::-webkit-input-placeholder {
color: #838383;
}

textarea:-moz-placeholder { /* Firefox 18- */
color: #838383;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
color: #838383;
}

textarea:-ms-input-placeholder {
color: #838383;
}

.loading-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.3);
  z-index: 7000;
  width:100%;
  top:0;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.loading-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  padding: 120px 0;
  z-index: 7001;
}
