.bottom-badge-container {
  width:100%;
  height:100vh;
  position:fixed;
  z-index:10000;
  top:0;
  display:flex;
  justify-content: flex-end;
  align-items:flex-end;
  pointer-events:none;

}


.bottom-badge {
  width:300px;
  height:40px;
  background-color: #FFFFFF;
  border-top: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  /* box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21); */
  z-index: 102;
  pointer-events:auto;
  border-radius: 8px 8px 0 0 ;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#838383;
  font-size:15px;
  margin-bottom:-80px;
  padding-bottom:80px;
  margin-right:10px;
}

@media (max-width:700px) {
  .bottom-badge {
    width:100%;
    margin-right:0px;
    border-radius: 0;

  }

}
