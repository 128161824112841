.project-block {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
  overflow: hidden;
  font-size:18px;
  flex-basis: 900px;

}

.project-block-header {
  height:159px;
  overflow: hidden;
  width:100%;
  position: relative;

}

@media (max-width: 600px) {
  .project-block-header {
    height:140px;
  }

}

.project-block-status-badge-container {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  display:flex;
  justify-content:flex-end;

}

.project-block-status-badge {
  opacity: 0.7;
  background: #FFFFFF;
  border-radius: 8px;
  padding-left: 6px;
  padding-right:6px;
  padding-top:1px;
  padding-bottom:1px;
  margin-top:19px;
  margin-right:23px;
  font-size:14px;

}

@media (max-width: 600px) {
  .project-block-status-badge-container  {


  }

  .project-block-status-badge {
    margin-top:10px;
    margin-right:10px;


  }

}
.project-block-header-background-image {
  width:100%;
  display:flex;
  object-fit:cover;
  margin-top:auto auto auto auto;
  opacity:1;
  max-height:159px;

}

.project-block-header-container {
  background-color:rgba(0,0,0,0.73);
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:flex;

}

.project-block-title {
  width:394px;
  text-align:center;
  color:#FFFFFF;
  font-size:30px;
  font-weight: 500;

}

.project-block-title-placeholder {
  width:394px;
  text-align:center;
  color:#FFFFFF;
  font-size:30px;
  font-weight: 500;
  opacity:0.7;

}

@media (max-width: 600px) {
  .project-block-title {
    font-size:22px;
    padding:0 5px 0 5px;

  }

  .project-block-title-placeholder {
    font-size:22px;
    padding:0 5px 0 5px;

  }
}

.project-block-meta-data-section {
  //border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 1px #EBEDF2;
  display:flex;
  align-items: center;
  justify-content: center;
}

.meta-data-block-1 {
  padding-top:16px;
  padding-bottom:16px;
  padding-left: 10px;
  padding-right: 10px;
  width:50%;
  height:100%;
  text-align:center;
  border-right: 1px solid #EBEDF2;
  font-style: italic;

}

.meta-data-block-2 {
  padding-top:16px;
  padding-bottom:16px;
  padding-left: 10px;
  padding-right: 10px;
  width:50%;
  height:100%;
  text-align:center;
  box-shadow: -1px 0 0 #EBEDF2;
  font-style: italic;


}

.project-block-description-section {
  display:flex;
  align-items: center;
  justify-content: center;
}

.project-block-description {
  padding-top:32px;
  padding-bottom:15px;
  text-align:center;
  max-width:773px;
  padding-right:10px;
  padding-left:10px;

}

.project-block-skill-container {
  align-items: center;
  justify-content: center;
  padding-bottom:15px;
  width:100%;

}

.project-block-skill-blocks-container {
  display:flex;
  align-items: center;
  justify-content: center;
  padding-bottom:12px;
  width:100%;
  flex-flow: row wrap;


}

.project-block-more-skills {
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  color:#767676;
  padding-top:5px;

}

.project-block-skill-block-container {
  display:inline-block;

}


.project-block-see-all {
  padding-top:18px;
  padding-bottom:18px;
  text-align:center;
  color:#358EFE;
  opacity:1;
  font-size:16px;
  border-top: 1px solid #EBEDF2;

}

.project-block-see-all:hover {
  opacity:0.6;


}
