.overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,.7);
  z-index: 5000;
  width:100%;

}

.add-skill-modal-container {
  display:flex;
  margin-top:69px;
  margin-left: 10px;
  margin-right:10px;
}

.add-skill-modal {
  margin:0 auto 0 auto;
  width:600px;
  background: #FFFFFF;
  border-radius: 8px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.add-skill-modal-top-bar {
  height:50px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.add-skill-modal-x-container {
  padding-right:20px;
  padding-top:15px;

}

.add-skill-modal-x {
  opacity:0.5;

}

.add-skill-modal-x:hover {
  opacity:1;

}

.add-skill-modal-main-body {

  width:100%;
  border-bottom: 1px solid #EBEDF2;
  padding-bottom:42px;
}

.add-skill-modal-title-container {
  display:flex;

}

.add-skill-modal-title {
  width:390px;
  margin: 0 auto 0 auto;

}

.add-skill-modal-title-label {
  font-size: 30px;
  font-weight: 500;
}

.add-skill-input-field-container {
  display:flex;
  justify-content: center;
  margin-top:30px;

}

.add-skill-input-field-container input{
  width:352px;
  height:65px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}


.new-skill-warning-message-container {
  margin: 20px auto -10px auto;
  display:flex;

}

.new-skill-warning-message {
  margin: 0 auto 0 auto;
  width:70%;
  color: #838383;
  font-size: 14px;


}


.add-skill-modal-button-section {
  display:flex;

}

.add-skill-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: space-between;
  font-size: 18px;
}

.add-skill-modal-close-button {
  height:52px;
  width:169px;
  text-align: center;
  display:inline-block;
  background-color: none;
  color:#5C5C5C;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #DCDCDC inset;
  opacity:1;
}

.add-skill-modal-close-button:hover {
  opacity:0.6;


}

.add-skill-modal-close-button-label {
  margin-top:13px;

}

.add-skill-modal-add-skill-button {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
}

.add-skill-modal-add-skill-button-disabled {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}

.add-skill-modal-add-skill-button:hover {
  opacity:0.6;


}

.add-skill-modal-add-skill-button-label {
  margin-top:13px;

}





/* MOBILE VIEW */

@media (max-width: 600px) {
  .add-skill-modal-title {
    text-align: center;

  }

  .add-skill-modal {




  }

}
