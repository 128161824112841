.profile-header {
  border-bottom: 1px solid #EBEDF2;

}


.profile-header-buttons-main-container {
  position:absolute;
  display:flex;
  justify-content: flex-end;
  width:100%;
  pointer-events:none;
}

.profile-header-buttons-sub-container {
  width:173px;
  margin-right:4.5vw;
  display:flex;
  flex-wrap: wrap;
  pointer-events:auto;
}


.profile-header-button {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  opacity:1;
  margin-bottom:18px;
  /* pointer-events:auto; */

}

@media (max-width: 900px) {
  .profile-header-buttons-main-container{
    position:static;
  }

  .profile-header-buttons-sub-container {
    width:100%;
    margin-right:0;
    position:static;
    display:flex;
    justify-content:space-around;
    margin-top:10px;
  }

  .profile-header-button {
    margin-bottom:10px;

  }

  .profile-header-button:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


  }

}

.profile-header-button:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


}

.profile-header-button-label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;

}

.profile-header-button-icon {
  margin-right:7px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.profile-header-button-label {
  color:#838383;

}

.profile-header-button-label-red {
  color:#348EFF;
  /* color:#EF7879; */
  /* color:#838383; */
}


.profile-header-container {
  display:flex;
  margin-top:38px;
  margin-bottom:65px;
  align-items: center;
  justify-content: center;
}

.profile-header-main-container {
  padding-left: 15px;
  padding-right:15px;


}

.profile-picture-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.profile-picture-border {
  height:120px;
  width:120px;
  background: #FFFFFF;
  border: 1px solid #EFF0F5;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius:60px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.profile-picture {
  background: #D8D8D8;
  height: 111px;
  width:111px;
  border-radius: 55.5px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.profile-picture img {
  height:100%;
  width:100%;
  object-fit: cover;

}

.profile-picture-letter {
  font-size:40px;
  color:#959595;

}

.profile-header-name-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  text-align:center;

}


.profile-header-name {
  font-size: 50px;
  font-weight:500;
  margin-top:16px;
}

.profile-header-bio-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.profile-header-bio {
  font-size:20px;
  font-style: italic;
  max-width:430px;
  text-align:center;
  line-height: 24px;
  margin-top:5px;

}

.profile-header-cta-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.profile-header-cta {
  font-size:20px;
  color:#348EFF;
  text-align:center;
  margin-top:31px;
  opacity:1;
}

.profile-header-cta:hover {
    opacity:0.6;

}
