.faq-card {
  display:flex;
  max-width:900px;
  flex-basis:900px;
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  padding:40px 56px 40px 56px;
  margin-top:36px;
  border-radius: 8px;
}

.faq-card-inner-container {
  width:100%;
  height:100%;

}

.faq-card-question-title {
  font-weight: 500;
  font-size: 22px;
  color: #242424;
  margin-bottom: 16px;
}

.faq-card-question-answer {
  font-size: 20px;
}
