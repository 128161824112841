.homepage-scroll-top-bar {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  position:fixed;
  z-index:1;
  background:#FFF;
  height:40px;
  display:flex;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  opacity:1;
  visibility: visible;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1.0);

}

.homepage-scroll-top-bar-hidden {
  opacity:0;
  visibility: hidden;
}

.homepage-scroll-bar-cta{
  font-size: 18px;
  color: #348EFF;
}

.homepage-scroll-bar-cta:hover{
  opacity:0.6;
}

.homepage-header-bar-container{
display:flex;
width: 1200px;
justify-content: space-between;
margin-left: 25px;
margin-right: 25px;
}


.homepage-header-bar {
  height:97px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;

}

.homepage-header-bar-logo {
  display:flex;
  align-items: center;
  font-size:22px;
  font-weight: 500;

}

.homepage-header-bar-logo-2 {
  display:flex;
  align-items: center;
  font-size:22px;
  font-weight: 500;
  margin-left: 9px;
  line-height:22px;
  margin-bottom:3px;

}

.homepage-header-bar-container {
  display:flex;
  width: 1200px;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;

}

.homepage-header-bar-links {
  display:flex;

}

.homepage-header-bar-links-link-container {
  margin-left:60px;
}

.homepage-header-bar-links-link-container-text {
  font-size: 16px;
  color: #838383;
}

.homepage-header-bar-links-link-container-text:hover {
  color: #348EFF;
}



.homepage-container {
  text-align:center;
  padding-bottom:200px;
  margin-left:25px;
  margin-right:25px;
}

.homepage-hero-section {
  width:100%;
  margin-top:17px;

}

.homepage-hero-section-title {
  font-weight: 500;
  font-size: 100px;
  color: #242424;
  letter-spacing: 0;
  text-align: center;


}

@media only screen and (max-width: 700px) {
  .homepage-hero-section-title {
    font-size: 50px;
  }
}

.homepage-hero-section-sub-title-container {
  display:flex;
  justify-content: center;

}


.homepage-hero-section-sub-title {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;
  margin-top:10px;
  display:flex;
  max-width:688px;

}

.homepage-hero-section-cta-button-container {
  display:flex;
  justify-content: center;

}

.homepage-hero-section-cta-button {
  width:256px;
  height:56px;
  background: #348EFF;
  border-radius: 7.2px;
  margin-top:46px;
  display:flex;
  align-items: center;
  justify-content: center;

}

.homepage-hero-section-cta-button:hover {
  background: #69ABFF;

}

.homepage-hero-section-cta-button-label {
  font-weight:400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;

}

.homepage-hero-section-profile-image-container-center{
  margin-top: 82px;
  display: flex;
  justify-content: center;
  width:100%;

}

.homepage-hero-section-profile-image-container {
  display:flex;
  max-width: 900px;

}


.homepage-hero-section-profile-image {
  box-shadow: 0 0 34px 0 rgba(189,189,206,0.21);
  width:100%;
  height:100%;
}

.homepage-about-section {
  width:100%;
  text-align: center;

}

.homepage-about-section-section-header-container {
  margin-top:103px;

}


.homepage-section-header{
  font-weight:500;
  font-size: 50px;
  color: #242424;
  text-align: center;
}

.homepage-about-section-section-body-container {
  display:flex;
  justify-content: center;
  width:100%;
  margin-top:25px;
}


.homepage-section-body {
  font-size: 25px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;

  max-width:732px;

}

.homepage-section-body-highlight {
  background-color:#D5E8FF;

}

.homepage-about-section-profile-image-container-center{
  margin-top: 25px;
  display: flex;
  justify-content: center;
  width:100%;

}

.homepage-about-section-profile-image-container {
  display:flex;
  max-width: 750px;

}


.homepage-about-section-profile-image {
  width:100%;
  height:100%;
}

.homepage-divider-container {
  width:100%;
  display:flex;
  justify-content: center;

}

.homepage-divider {
  width:169px;
  height:3px;
  background: #F0F2F5;
  margin-top:103px;
  margin-bottom:71px
}

.homepage-how-it-works-section-italics-label {
  font-style: italic;
  font-size: 35px;
  color: #838383;
  text-align: center;


}

.homepage-how-it-works-section-down-arrow {

  margin-top:37px;
}

.homepage-how-it-works-section-step-container {
  margin-top:33px;

}

.homepage-how-it-works-section-profile-image-container-center{
  margin-top: 56px;
  display: flex;
  justify-content: center;
  width:100%;

}

.homepage-how-it-works-section-down-arrow-2 {

  margin-top:77px;
}

.homepage-section-body-linked {
  text-decoration: underline;

}

.homepage-section-body-linked:hover {
  opacity:0.6;

}


.homepage-footer {
  height:57px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #EBEDF2;

}

.homepage-footer-container {
  display:flex;
  width: 1200px;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
  flex-wrap: wrap;

}

.homepage-footer-links {
  display:flex;

}

.homepage-footer-links-link-container {
  margin-left:60px;
}

.homepage-footer-links-link-container-text {
  font-size: 16px;
  color: #838383;
}

.homepage-footer-links-link-container-text-copyright {
  font-size: 16px;
  color: #838383;
}

.homepage-footer-links-link-container-text:hover {
  color: #348EFF;
}

@media (max-width: 800px) {
  .homepage-footer-container {
    display:flex;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
    flex-wrap: wrap;

  }

  .homepage-footer {
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #EBEDF2;
    padding:10px 0 10px 0;

  }

  .homepage-footer-links {
    padding:10px 0 10px 0;

  }

  .homepage-footer-links-link-container {
    margin-left:10px;
    margin-right:10px;
  }

}
