.profile-projects-section {
  width:100%;
  background-color: #FFFFFF;
  padding-bottom:15px;
}

.profile-projects-new-project-button-container {
  position:absolute;
  display:flex;
  justify-content: flex-end;
  width:100%;
  pointer-events:none;
}


.profile-projects-new-project-button {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-right:4.5vw;
  margin-top:38px;
  opacity:1;
  pointer-events:auto;
}

.profile-projects-new-project-button-loading {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-right:4.5vw;
  margin-top:38px;
  opacity:0.6;
}

@media (max-width: 900px) {
  .profile-projects-new-project-button-container {
    position:static;
    display:flex;
    justify-content: center;
    width:100%;
  }

  .profile-projects-new-project-button {
    background: #FFFFFF;
    border: 1px solid #EBEDF2;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
    border-radius: 8px;
    height:53px;
    width:173px;
    margin-right:0;
    margin-top:32px;
    opacity:1;
  }

  .profile-projects-new-project-button:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


  }

}

.profile-projects-new-project-button:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


}

.profile-projects-new-project-button-label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;

}

.profile-projects-new-project-button-icon {
  margin-right:7px;
  display: flex;
  align-items: center;
  justify-content: center;


}

.profile-projects-new-project-button-label {
  color:#838383;

}

.profile-projects-section-container {
  display:flex;
  padding-top:49px;
  padding-bottom:65px;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-projects-section-container-main {
  width:100%;


}

@media (max-width: 600px) {

  .profile-projects-section-container {
    display:flex;
    padding-top:32px;
    padding-bottom:65px;
    align-items: center;
    justify-content: center;

  }


}

.profile-projects-section-title-container {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-projects-section-title {
  font-size: 35px;
  font-weight: 500;
}

.profile-projects-section-project-blocks-container {
  padding-right: 15px;
  padding-left: 15px;


}

.project-block-container {
  margin-top:54px;
  display:flex;
  width:100%;
  justify-content: center;

}


.rofile-projects-section-empty-state {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.profile-projects-section-empty-state-message {
  font-size:16px;
  color:#767676;
  margin-top:6px;
  text-align:center;
}

.profile-projects-new-project-button-container-empty {
  display:flex;
  justify-content: center;
  width:100%;
}


.profile-projects-new-project-button-empty {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-top:28px;
  opacity:1;
}

.profile-projects-new-project-button-empty-loading {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  height:53px;
  width:173px;
  margin-top:28px;
  opacity:0.6;
}

.profile-projects-new-project-button-empty:hover {
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.07);


}
