.edit-header-action-bar {
  background: #404040;
  border-bottom: 1px solid #333333;
  height:64px;
  width:100%;

}

.edit-header-action-bar-container {
  width:100%;
  height:100%;
  display:flex;
  justify-content: flex-end;
  align-items: center;

}

.edit-header-action-bar-button-container {
  display:flex;
  margin-right:5vw;

}

@media (max-width: 600px) {
  .edit-header-action-bar-button-container {
    margin-right:0;

  }

  .edit-header-action-bar-container {
    justify-content: center;

  }

}

.edit-header-action-bar-apply-button {
  background: #348EFF;
  border-radius: 9px;
  width:126px;
  height:36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left:18px;

}

.edit-header-action-bar-apply-button:hover {
  opacity:0.7;

}

.edit-header-action-bar-apply-button-label {
  font-size: 18px;
  color: #FFFFFF;
  text-align:center;
  margin-bottom:1px;
}

.edit-header-action-bar-cancel-button {
  opacity: 0.6;
  border: 1px solid #EBEDF2;
  border-radius: 9px;
  width:126px;
  height:36px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.edit-header-action-bar-cancel-button:hover {
  opacity:0.4;

}

.edit-header-action-bar-cancel-button-label {
  font-size: 18px;
  color: #FFFFFF;
  text-align:center;
  margin-bottom:1px;
}


.gallery {
  overflow:hidden;
  height:178px;
  background-color:rgba(250,251,254,1);
  border-bottom: 1px solid rgba(235,237,242,1);
  position:absolute;
  width:100%;
  overflow-x:hidden;
  z-index:1;
}

.gallery-container {
  width:100%;
  height:178px;
  display:grid;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom:20px;
}

.image-wrapper {
  width: 192px;
  height:130px;
  overflow:hidden;
  background: #D8D8D8;
  border-radius: 3px;
  align-self:center;
  margin-left:10px;
  flex: 0 0 auto;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  cursor: pointer;
}

.image-wrapper:hover {
  transform: scale(1.02, 1.02);
  box-shadow: 0 6px 16px rgba(0,0,0, 0.2);

}

.gallery-overlay {
  width:100%;
  background:#FFFFFF;
  opacity:0.9;
  position:absolute;
  height:calc(100% - 405px);

}
