.edit-profile-modal-overlay{
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.7);
  z-index: 3000;
  width:100%;
  top:0;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.edit-profile-modal-container {
  margin-top:5VH;
  margin-left: 10px;
  margin-right:10px;
}

.edit-profile-modal {
  margin:0 auto 0 auto;
  font-size:18px;
  background: #FFFFFF;
  border-radius: 8px;
  display:flex;
  flex-basis: 600px;
  max-width:600px;

}

.edit-profile-modal-responsive-container{
  width:100%;


}

.edit-profile-modal-top-bar {
  height:33px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.edit-profile-modal-x-container {
  padding-right:20px;
  padding-top:15px;

}

.edit-profile-modal-x {
  opacity:0.5;

}

.edit-profile-modal-x:hover {
  opacity:1;

}

.edit-profile-modal-title-body {

  width:100%;
  border-bottom: 1px solid #EBEDF2;
  padding-bottom:13px;
}

.edit-profile-modal-title-container {
  display:flex;

}

.edit-profile-modal-title {
  width:390px;
  margin: 0 auto 0 auto;

}

.edit-profile-modal-title-label {
  font-size: 30px;
  font-weight: 500;
  text-align:center;
}

.edit-profile-modal-scroll-container {
  overflow-y:auto;
  overflow-x:hidden;
  height:60VH;


}

.edit-profile-modal-section-1 {
  padding-top: 26px;
  padding-bottom: 26px;
  width:100%;
  border-bottom: 1px solid #EBEDF2;
}

.edit-profile-modal-section-1-container{
  align-items: center;
  height:100%;
  padding-left:50px;
  padding-right:50px;
  flex-basis: 600px;
  max-width:600px;
  display:flex;


}


@media only screen and (max-width: 600px) {
  .edit-profile-modal-section-1-container{
    display:block;
  }
}

.edit-profile-modal-username-field-container{
  display:flex;
  flex-basis: 362px;
  max-width:362px;


}




.edit-profile-modal-username-field-label {
  font-style: italic;
  margin-bottom: 3px;

}

.edit-profile-modal-username-field-description {
  font-size: 14px;
  margin-top: 3px;
  color: #838383;
  max-width:362px;

}

.username-in-description {
  color:#242424;


}





.edit-profile-modal-username-field {
  height:65px;
  width:362px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}

.edit-profile-modal-profile-photo {
  display:flex;
  justify-content: flex-end;
  width:100%;

}

@media (max-width: 600px) {
  .edit-profile-modal-profile-photo {
    display:block;
    margin-top:15px;

  }

}

.edit-profile-modal-profile-picture {
  background: #D8D8D8;
  height: 111px;
  width:111px;
  border-radius: 55.5px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.edit-profile-modal-profile-photo-exists{
  height: 100%;
  width:100%;
  position:relative;
  opacity:1;

}

.edit-profile-modal-profile-photo-exists:hover{
  opacity:0.6;

}

.edit-profile-modal-profile-picture img {
  height:100%;
  position:absolute;
  width:100%;
  object-fit: cover;

}

.edit-profile-modal-profile-photo-overlay{
  position:absolute;
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.63);
  display:flex;
  justify-content: center;
  align-items: center;

}



.edit-profile-modal-profile-photo-overlay-message{
  color:#FFFFFF;
  text-align: center;
  font-size:12px;
  line-height: 15px;
  width:61px;

}

.edit-profile-modal-profile-photo-doesnt-exists {
  height: 100%;
  width:100%;
  position:relative;
  opacity:1;


}

.edit-profile-modal-profile-photo-doesnt-exists:hover {
  opacity:0.6;


}

.edit-profile-modal-profile-picture-letter-container {
  position:absolute;
  height: 100%;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.edit-profile-modal-profile-picture-letter {
  font-size:40px;
  color:#959595;
  position:absolute;

}

.edit-profile-modal-profile-photo-upload{
  position:absolute;
  z-index:1000;
  height:100%;
  width:100%;
  padding-right:200px;
  margin-left:-200px;

}



.edit-profile-modal-section-2 {
  width:100%;
  border-bottom: 1px solid #EBEDF2;
}

.edit-profile-modal-section-2-container {
  height:100%;
  padding-left:50px;
  padding-right:50px;
  padding-bottom:36px;


}
.edit-profile-modal-section-2-row-1{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:36px;

}

.edit-profile-modal-section-2-column-1{
  width:100%;
  margin-right:17px;

}

.edit-profile-modal-section-2-column-2{
  width:100%;

}


@media (max-width: 600px) {
  .edit-profile-modal-section-2-row-1{
    display:block;
    align-items: center;
    justify-content: space-between;
    width:100%;


  }

  .edit-profile-modal-section-2-column-2 {
    padding-top:36px;


  }

}

.edit-profile-modal-first-name-field-container {
  display:flex;
  width:100%;

}

.edit-profile-modal-first-name-field-label {
  font-style: italic;
  margin-bottom: 3px;

}


.edit-profile-modal-first-name-field {
  height:65px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}


.edit-profile-modal-last-name-field-container {
  display:flex;
  width:100%;

}

.edit-profile-modal-last-name-field-label {
  font-style: italic;
  margin-bottom: 3px;
  margin-top:1

}


.edit-profile-modal-last-name-field {
  height:65px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}

.edit-profile-modal-bio-field-container {
  display:flex;

}

.edit-profile-modal-bio-field-label {
  font-style: italic;
  margin-bottom: 3px;

}


.edit-profile-modal-bio-field {
  height:65px;
  width:436px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}



.edit-profile-modal-section-3 {
  width:100%;
  border-bottom: 1px solid #EBEDF2;
}


.edit-profile-modal-section-3-container {
  padding-left:50px;
  padding-right:50px;
  width:100%;


}

.edit-profile-modal-section-3-row {
  width:100%;
  padding-bottom:36px;


}
.edit-profile-modal-section-3{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:36px;

}

.edit-profile-modal-call-to-action-field-container {
  display:flex;
  flex-basis:1000px

}

.edit-profile-modal-call-to-action-field-label {
  font-style: italic;
  margin-bottom: 3px;

}


.edit-profile-modal-call-to-action-field {
  height:65px;
  width:100%;
  flex-basis: 436px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #242424;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Brandon;
  outline: none;

}

.edit-profile-modal-call-to-action-field-description {
  font-size: 14px;
  margin-top: 3px;
  color: #838383;

}


.edit-profile-modal-button-section {
  display:flex;
  border-top: 1px solid #EBEDF2;

}

.edit-profile-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: space-between;
  font-size: 18px;
}

.edit-profile-modal-close-button {
  height:52px;
  width:169px;
  text-align: center;
  display:inline-block;
  background-color: none;
  color:#5C5C5C;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #DCDCDC inset;
  opacity:1;
}

.edit-profile-modal-close-button:hover {
  opacity:0.6;

}

.edit-profile-modal-close-button-label {
  margin-top:13px;

}

.edit-profile-modal-save-button-disabled {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}


.edit-profile-modal-save-button {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
}

.edit-profile-modal-save-button:hover {
  opacity:0.6;


}

.edit-profile-modal-save-button-label {
  margin-top:13px;

}
